import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  PinTextInputField,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";

const VerifyStockPopUp = ({
  display,
  setClose,
  selectedProduct,
  setLoading,
  setMessage,
  setToastPop,
  user,
  setRefresh,
  refresh,
  info,
}) => {
  const [count, setCount] = useState("");
  const [selectedBudtender, setSelectedBudTender] = useState(null);
  const [pin1, setPin1] = useState("");
  const [pinPopUp, setPinPopUp] = useState(false);
  const [userVerfiied, setUserVerified] = useState(false);
  const [storeUsers, setStoreUsers] = useState(null);

  const [stockData, setStaockData] = useState(null);

  const [completeCount, setCompleteCount] = useState(false);

  const [expectedAmount, setExpectedAmount] = useState("10");

  const [countComment, setCountComment] = useState("");

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreUsers(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const ConfirmAccess = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: selectedBudtender._id,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setPinPopUp(false);
        setUserVerified(true);
        setLoading(false);
        setPin1("");
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };

  // const getStockInformation = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           productId
  //         }),
  //       }
  //     );

  //     const responseData = await response.json();

  //     if (responseData.success) {
  //       setPinPopUp(false);
  //       setUserVerified(true);
  //       setLoading(false);
  //       setPin1("");
  //     } else {
  //       setLoading(false);
  //       setMessage(responseData.message);
  //       setToastPop(true);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     setMessage("No network try again later");
  //     setToastPop(true);
  //     console.log("error", err);
  //   }
  // };

  const CompleteStockVerification = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/stockVerify",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: selectedBudtender._id,
            productId: selectedProduct.product.product._id,
            productName: selectedProduct.product.product.productName,
            storeName: selectedBudtender.storeName,
            stockInventoryCardId: stockData._id,
            stockCount: count,
            storeId: stockData.storeId,
            expected: ReturnStockDateLastCheckAmount({
              productId: selectedProduct.product.product._id,
            }),
            countedBy:
              user.adminUserData.firstName + " " + user.adminUserData.lastName,
            comment: countComment,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setClose(null);
        setUserVerified(false);
        setCompleteCount(false);
        setLoading(false);
        setMessage(responseData.success);
        setToastPop(true);
        setPin1("");
        setCountComment("");
        setExpectedAmount("0");
        setRefresh(refresh ? false : true);
        setStaockData(null);
        setSelectedBudTender(null);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };
  const ReturnStockDateLastCheckAmount = ({ productId }) => {
    let stock = 0;
    if (info) {
      info.map((item) =>
        item.productId === productId ? (stock = item.currentStock) : null
      );
    }

    return Number(stock);
  };

  const ReturnStockInfomation = ({ productId }) => {
    let infoStock;
    if (info) {
      info.map((item) =>
        item.productId === productId ? (infoStock = item) : null
      );
    }

    return infoStock;
  };

  useEffect(() => {
    if (pin1.length === 4) {
      ConfirmAccess();
    }
  }, [pin1]);

  useEffect(() => {
    if (!userVerfiied && selectedProduct) {
      setPinPopUp(true);
    }
    if (selectedProduct) {
      setStaockData(
        ReturnStockInfomation({
          productId: selectedProduct.product.product._id,
        })
      );

      setExpectedAmount(
        ReturnStockDateLastCheckAmount({
          productId: selectedProduct.product.product._id,
        })
      );
    } else {
      setExpectedAmount("0");
      setCount("");
    }
  }, [selectedProduct]);
  useEffect(() => {
    GetStoreUsers();

    //get all store users
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100000000000,
        backgroundColor: "#00000098",
        height: display ? "100%" : 0,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        transition: "all 0.2s",
      }}
    >
      <div
        style={{
          backgroundColor: BlackAndWhite.secondary,
          paddingTop: 0,
          paddingBottom: 20,
          width: "50%",
          borderRadius: 8,
          WebkitBoxShadow: "0px 0px 7px 2px #00000050",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            marginTop: 20,
            width: "90%",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          VERIFY THIS PRODUCT'S STOCK
        </div>
        <div
          style={{
            display: "flex",
            marginTop: 20,
            alignItems: "center",
            width: "90%",
          }}
        >
          <div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 5,
              }}
            >
              PRODUCT NAME
            </div>
            <div>
              {selectedProduct
                ? selectedProduct.product.product.productName
                : "loading"}
            </div>
          </div>

          <div style={{ flex: 1 }} />
          <div>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 5,
              }}
            >
              PRODUCT CATEGORY
            </div>
            <div>
              {" "}
              {selectedProduct
                ? selectedProduct.product.product.productType
                : "loading"}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 40,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              minHeight: 90,
              minWidth: 90,
              maxWidth: 90,
              maxHeight: 90,
              height: 90,
              width: 90,
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 8,
              //   WebkitBoxShadow: "0px 0px 7px 2px #00000010",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 30,
              }}
            >
              {" "}
              {expectedAmount ? expectedAmount : "loading"}
            </div>
            <div style={{ marginBottom: 5, opacity: 0.9, fontSize: 12 }}>
              {" "}
              EXPECTED
            </div>
          </div>
          {/* <div style={{ flex: 1 }} /> */}
          <div style={{ marginBottom: 10, marginLeft: 40, marginRight: 150 }}>
            <TextInputField
              mini={true}
              width={250}
              size={"100%"}
              labelTitle={"PRODUCT CURRENT COUNT"}
              invert={true}
              fontsize={count !== "" ? 50 : 18}
              // type={"Number"}
              placeholder={"ADD AMOUNT HERE"}
              value={count}
              height={80}
              borderRadius={8}
            />
          </div>
        </div>
        {/* KEYBOARD */}
        {completeCount ? null : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: 40,
            }}
          >
            <div
              style={{
                flex: 0.25,

                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"1"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "1")}
                marginRight={10}
              />
              <BaseButtons
                label={"2"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "2")}
                marginRight={10}
              />
              <BaseButtons
                label={"3"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "3")}
              />
            </div>
            <div
              style={{
                flex: 0.25,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"4"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "4")}
                marginRight={10}
              />
              <BaseButtons
                label={"5"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "5")}
                marginRight={10}
              />
              <BaseButtons
                label={"6"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "6")}
              />
            </div>
            <div
              style={{
                flex: 0.25,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"7"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "7")}
                marginRight={10}
              />
              <BaseButtons
                label={"8"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "8")}
                marginRight={10}
              />
              <BaseButtons
                label={"9"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "9")}
              />
            </div>
            <div
              style={{
                flex: 0.25,

                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                label={"."}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + ".")}
                marginRight={10}
              />
              <BaseButtons
                label={"0"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count + "0")}
                marginRight={10}
              />
              <BaseButtons
                label={"BACK"}
                fontSize={20}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setCount(count.substring(0, count.length - 1))}
              />
            </div>
          </div>
        )}
        {pinPopUp ? (
          <div
            style={{
              position: "fixed",
              backgroundColor: "#00000050",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: 0,
              zIndex: 1000000,
            }}
          >
            <div
              style={{
                height: 700,
                width: 500,
                backgroundColor: BlackAndWhite.secondary,
                borderRadius: 10,
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflow: "hidden",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "90%",
                  marginTop: 20,
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                SELECT USER AND ENTER YOUR PIN
              </div>
              {selectedBudtender ? null : (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    minHeight: 300,
                    maxHeight: 700,
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  {storeUsers
                    ? storeUsers.map((user, index) => {
                        return (
                          <div
                            onClick={() => setSelectedBudTender(user)}
                            key={index}
                            style={{
                              width: "99%",
                              height: 70,

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                              marginBottom: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "95%",
                                width: "90%",
                                backgroundColor: BlackAndWhite.secondary,
                                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                                borderRadius: 8,
                              }}
                            >
                              {user.firstName + " " + user.lastName}
                            </div>
                          </div>
                        );
                      })
                    : "Loading"}
                </div>
              )}

              {selectedBudtender ? (
                <>
                  <div
                    onClick={() => {
                      setSelectedBudTender(null);
                      setPin1("");
                    }}
                    style={{
                      height: 100,
                      width: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "85%",
                        borderStyle: "solid",
                        height: 90,
                        borderRadius: 8,
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                      }}
                    >
                      <div
                        style={{
                          height: "100%",
                          width: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="image"
                          src={require("../../../../../../images/material-icons/profileBlack.png")}
                          style={{ height: 70 }}
                        />
                      </div>
                      {selectedBudtender
                        ? selectedBudtender.firstName +
                          " " +
                          selectedBudtender.lastName
                        : "loading"}
                    </div>
                  </div>

                  <div style={{ width: "85%", fontSize: 14, marginTop: 20 }}>
                    PLEASE ENTER YOUR PIN
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginBottom: 20,
                      minHeight: 70,
                      fontSize: 50,
                      alignItems: "center",
                      opacity: pin1 === "" ? 0.5 : 1,
                    }}
                  >
                    {/* <div style={{ marginLeft: 20 }} /> */}
                    {pin1 === "" ? "0 0 0 0" : pin1}
                  </div>

                  <div
                    style={{
                      flex: 1,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        flex: 0.25,

                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"1"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "1")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"2"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "2")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"3"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "3")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 0.25,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"4"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "4")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"5"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "5")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"6"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "6")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 0.25,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"7"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "7")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"8"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "8")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"9"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "9")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 0.25,

                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"0"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={200}
                        height={70}
                        borderRadius={8}
                        onClick={() => setPin1(pin1 + "0")}
                        marginRight={14}
                      />
                      <BaseButtons
                        label={"BACK"}
                        fontSize={20}
                        mini={true}
                        marginTopFalse={true}
                        size={77}
                        height={70}
                        borderRadius={8}
                        onClick={() =>
                          setPin1(pin1.substring(0, pin1.length - 1))
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 15,
                }}
              >
                <BaseButtons
                  label={"CANCEL"}
                  mini={true}
                  marginTopFalse={true}
                  // marginRight={selectedBudtender ? 20 : 0}
                  borderRadius={8}
                  size={"90%"}
                  onClick={() => {
                    setPinPopUp(false);
                    setSelectedBudTender(null);
                    setPin1("");
                    setClose(null);
                  }}
                />
                {/* {selectedBudtender ? (
              <BaseButtons
                label={"CONFIRM"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                size={"45%"}
                onClick={() => setUserVerified("true")}
              />
            ) : null} */}
              </div>
            </div>
          </div>
        ) : null}
        {Number(expectedAmount) > Number(count) && completeCount ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextAreaInput
              height={150}
              labelTitle={"Comments"}
              color={"#000000"}
              width={"100%"}
              size={"80%"}
              value={countComment}
              onChange={(e) => setCountComment(e.target.value)}
            />
          </div>
        ) : null}

        {completeCount ? (
          <div style={{ display: "flex" }}>
            <BaseButtons
              label={"CANCEL"}
              mini={true}
              borderRadius={8}
              height={50}
              marginRight={20}
              onClick={() => {
                setCompleteCount(false);
              }}
              size={200}
            />{" "}
            <BaseButtons
              label={"SUBMIT STOCK COUNT"}
              mini={true}
              borderRadius={8}
              onClick={() => CompleteStockVerification()}
              size={200}
            />{" "}
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <BaseButtons
              label={"CLOSE"}
              mini={true}
              borderRadius={8}
              height={50}
              marginRight={20}
              onClick={() => {
                setClose(null);
                setUserVerified(false);
                setSelectedBudTender(null);
              }}
            />{" "}
            <BaseButtons
              label={"CONFIRM"}
              mini={true}
              borderRadius={8}
              height={50}
              onClick={() => {
                setCompleteCount(true);
              }}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyStockPopUp;
