import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  CustomerTicketsDisplayPanel,
  PrescriptionTicketsDisplayPanel,
  SystemTicketsDisplayPanel,
  TopTabController,
} from "./components";

const PosSupportDisplay = ({
  user,
  setLoading,
  height,
  width,
  setToastPop,
  setMessage,
}) => {
  const [refresh, setRefresh] = useState(false);

  const [selectedDisplay, setSelectedDisplay] = useState("customer");
  const [filterState, setFilterState] = useState("PENDING");
  const [supportTickectsArrayCustomers, setSupportTickectsArrayCustomers] =
    useState(null);
  const [supportTickectsArrayBugs, setSupportTickectsArrayBugs] =
    useState(null);
  const [supportTickectsOtherArray, setSupportTickectsOtherArray] =
    useState(null);
  const [
    supportTickectsProductSuggestionArray,
    setSupportTickectsProductSuggestionArray,
  ] = useState(null);
  const [
    supportTickectsArrayPrescription,
    setSupportTickectsArrayPrescription,
  ] = useState(null);

  const GetSupportPrescriptionTickets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getSupportTicketbyType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            type: "PRESCRITION",
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsArrayPrescription(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const GetSupportCustomerTickets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getSupportTicketbyType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            type: "CUSTOMER",
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsArrayCustomers(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const GetSupportSystemBugTickets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getSupportTicketbyType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            type: "SYSTEM",
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsArrayBugs(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const GetSupportOtherTickets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getSupportTicketbyType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            type: "OTHER",
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsOtherArray(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const GetSupportProductSuggestionTickets = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getSupportTicketbyType",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            type: "PRODUCT SUGGESTION",
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsProductSuggestionArray(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user) {
      GetSupportSystemBugTickets();
      GetSupportCustomerTickets();
      GetSupportPrescriptionTickets();
      GetSupportOtherTickets();
      GetSupportProductSuggestionTickets();
    }
  }, [refresh]);
  return (
    <div
      style={{
        backgroundColor: "#00000010",
        height: height,
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <TopTabController
        selectedDisplay={selectedDisplay}
        setSelectedDisplay={setSelectedDisplay}
        setFilterState={setFilterState}
      />
      {selectedDisplay === "customer" ? (
        <CustomerTicketsDisplayPanel
          filterState={filterState}
          tickets={supportTickectsArrayCustomers}
          user={user}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setRefresh={setRefresh}
          refresh={refresh}
          setLoading={setLoading}
        />
      ) : null}
      {selectedDisplay === "bugs" ? (
        <SystemTicketsDisplayPanel
          filterState={filterState}
          tickets={supportTickectsArrayBugs}
          user={user}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setRefresh={setRefresh}
          refresh={refresh}
          setLoading={setLoading}
        />
      ) : null}
      {selectedDisplay === "prescriptions" ? (
        <PrescriptionTicketsDisplayPanel
          filterState={filterState}
          tickets={supportTickectsArrayPrescription}
          user={user}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setRefresh={setRefresh}
          refresh={refresh}
          setLoading={setLoading}
        />
      ) : null}
      {selectedDisplay === "other" ? (
        <PrescriptionTicketsDisplayPanel
          filterState={filterState}
          tickets={supportTickectsOtherArray}
          user={user}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setRefresh={setRefresh}
          refresh={refresh}
          setLoading={setLoading}
        />
      ) : null}
      {selectedDisplay === "product suggestion" ? (
        <PrescriptionTicketsDisplayPanel
          filterState={filterState}
          tickets={supportTickectsProductSuggestionArray}
          user={user}
          setMessage={setMessage}
          setToastPop={setToastPop}
          setRefresh={setRefresh}
          refresh={refresh}
          setLoading={setLoading}
        />
      ) : null}
    </div>
  );
};
export default PosSupportDisplay;
