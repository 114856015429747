import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const CartItem = ({
  index,
  item,
  NameAssemble,
  EditQty,
  AddBackToProducts,
  removeFromRecipets,
}) => {
  const [qty, setQty] = useState(1);

  //   EditQty({ idIndex: index, qty: qty });
  useEffect(() => {
    EditQty({ idIndex: index, qty: qty });
  }, [qty]);
  useEffect(() => {
    if (item) {
      setQty(item.qtyCard.qty);
    } else {
      setQty(item.qtyCard.qty);
    }
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: 90,
        // borderRadius: 10,
        backgroundColor: BlackAndWhite.secondary,
        marginTop: index === 0 ? 20 : 2,
        // WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ marginLeft: 10, marginRight: 5 }}>
        <img alt="icon" style={{ height: 50 }} src={item.product.imageUrl} />
      </div>
      <div style={{ fontSize: 10, width: 170, height: 50 }}>
        {NameAssemble({ product: item.product })}
      </div>
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          display: "flex",
          flexDirection: "column",
          minWidth: 80,
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
            marginTop: 5,
          }}
        >
          ITEM
        </div>
        <div style={{ marginTop: 5, marginBottom: 17, fontSize: 14 }}>
          {item ? "R " + Number(item.product.retailPriceInc).toFixed(2) : null}
        </div>
      </div>
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          display: "flex",
          flexDirection: "column",
          minWidth: 80,
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
            marginTop: 5,
          }}
        >
          TOTAL
        </div>
        <div style={{ marginTop: 5, marginBottom: 17, fontSize: 14 }}>
          {item
            ? "R " +
              (
                Number(item.product.retailPriceInc) * Number(item.qtyCard.qty)
              ).toFixed(2)
            : null}
        </div>
      </div>
      <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 15 }}>
        <TextInputField
          isDisplay={true}
          value={qty}
          mini={true}
          size={30}
          labelTitle={"Qty"}
          height={10}
          invert={true}
          type={"number"}
          onChange={(e) => {
            setQty(e.target.value);
          }}
        />
      </div>

      {/* <BaseButtons
        label={"UPDATE"}
        mini={true}
        marginTopFalse={true}
        borderRadius={8}
        size={80}
        height={30}
        onClick={() => {
          EditQty({ idIndex: index, qty: qty });
        }}
      /> */}
      <div style={{ marginRight: 10 }}>
        <BaseButtons
          label={"X"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          size={30}
          height={30}
          onClick={() => {
            AddBackToProducts(item.product);
            removeFromRecipets(index);
          }}
        />
      </div>
    </div>
  );
};

export default CartItem;
