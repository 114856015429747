import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../../../../../themes/Colors";

const LineItemCard = ({
  data,
  statusCheck,
  EditQty,
  index,
  onRemoveClick,
  storeId,
  isPosCheckREcieve,
  display,
  isPos,
  isTransferDocLine,
}) => {
  const [qty, setQty] = useState(null);
  const [baseQty, setBaseQty] = useState(null);

  const [isSaved, setIsSaved] = useState(null);

  const [expectedAmount, setExpectedAmount] = useState(0);

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.product.productName +
        " | " +
        product.product.productSubType +
        " | " +
        product.product.thcType +
        " | " +
        product.product.thcContent;
      return name;
    }
  };

  useEffect(() => {
    if (data) {
      setQty(data.qtyCard.qty);
      setBaseQty(data.qtyCard.qty);
      if (display.status === "in-transit" || display.status === "complete") {
        setExpectedAmount(data.qtyCard.expected);
      } else {
        setExpectedAmount(data.qtyCard.qty);
      }
    }
  }, [data]);
  return (
    <div
      style={{
        width: "100%",
        height: isTransferDocLine ? 50 : 85,
        borderRadius: isTransferDocLine ? 0 : 10,
        overflow: "hidden",
        borderStyle: isTransferDocLine ? "none" : "solid",
        borderWidth: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isTransferDocLine ? (
          <img
            src={data.product.imageUrl}
            alt="product"
            style={{ height: "100%" }}
          />
        ) : null}
      </div>
      <div style={{ marginLeft: 20, fontSize: isTransferDocLine ? 10 : 14 }}>
        {NameAssemble({ product: data })}
      </div>
      <div style={{ flex: 1 }} />
      {isPosCheckREcieve ? (
        <TextInputField
          type={"number"}
          labelTitle="EXPECTED"
          invert={true}
          mini={true}
          height={20}
          marginTop={-5}
          value={expectedAmount}
          size={isTransferDocLine ? 40 : 80}
          // onChange={(e) => setQty(e.target.value)}
          readOnly={true}
          fontFamily={"Arial"}
          fontsize={isTransferDocLine ? 10 : 10}
          fontsizeLabel={isTransferDocLine ? 8 : 10}
          paddingBottomTextBox={isTransferDocLine ? true : false}
          paddingTopTextBox={isTransferDocLine ? true : false}
        />
      ) : null}
      {isPosCheckREcieve ? <div style={{ minWidth: 10 }} /> : null}
      <TextInputField
        type={"number"}
        labelTitle="QTY"
        invert={true}
        mini={true}
        height={20}
        marginTop={-5}
        value={isTransferDocLine ? "" : qty}
        onChange={(e) => setQty(e.target.value)}
        readOnly={!statusCheck}
        size={80}
        fontFamily={"Arial"}
        placeholder={" "}
        fontsize={isTransferDocLine ? 5 : 10}
        fontsizeLabel={isTransferDocLine ? 8 : 10}
        paddingBottomTextBox={isTransferDocLine ? true : false}
        paddingTopTextBox={isTransferDocLine ? true : false}
      />
      <div style={{ minWidth: 20 }} />
      {statusCheck && !isTransferDocLine ? (
        <BaseButtons
          label={isSaved ? "EDIT" : "SAVE EDIT"}
          mini={true}
          borderRadius={8}
          height={40}
          marginTopFalse={true}
          marginRight={20}
          onClick={
            isSaved
              ? () => setIsSaved(false)
              : () => {
                  EditQty({
                    idIndex: index,
                    qty: qty,
                    display: display.status,
                  });
                  setIsSaved(true);
                }
          }
          disable={qty !== baseQty ? false : true}
        />
      ) : null}

      {(statusCheck && !isPos) || !isTransferDocLine ? (
        <BaseButtons
          label={"REMOVE"}
          mini={true}
          borderRadius={8}
          height={40}
          marginTopFalse={true}
          marginRight={20}
          onClick={onRemoveClick}
        />
      ) : null}
      {isTransferDocLine ? (
        <div
          style={{
            width: "100%",

            position: "absolute",
            bottom: -1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              height: 1,
              backgroundColor: "#B5BDC6",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default LineItemCard;
