import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import TransferItem from "../transfer-item/TransferItem";

const StoreTransferListItem = ({
  selectedIndex,
  index,
  store,
  setSelectedIndex,
  transferDocsArray,
  screenDisplay,
  SelectTranfersForView,
  storesArray,
}) => {
  const ReturnStoreNameItems = ({ productId }) => {
    let stock;
    // info.map((item) =>
    //   item.productId === productId ? (stock = item.currentStock) : null
    // );

    return Number(stock).toFixed(2);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "flex-start",
      }}
    >
      <div
        onClick={() => setSelectedIndex(index)}
        style={{
          height: "100%",
          width: "100%",

          display: "flex",
          alignItems: "center",
          minHeight: 60,
        }}
      >
        <div style={{ marginLeft: 20, fontWeight: "bold" }}>
          {store.storeName}
        </div>
      </div>
      {selectedIndex === index ? (
        <div
          style={{
            width: "100%",
            backgroundColor: BlackAndWhite.secondary,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {transferDocsArray
            ? transferDocsArray.length >= 1
              ? transferDocsArray.map((doc, index) => {
                  return doc.to.toLowerCase() ===
                    store.storeName.toLowerCase() || doc.to === store._id ? (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        minHeight: 60,
                        justifyContent: "center",
                      }}
                    >
                      <TransferItem
                        doc={doc}
                        SelectTranfersForView={SelectTranfersForView}
                      />
                    </div>
                  ) : null;
                })
              : null
            : null}
        </div>
      ) : null}
    </div>
  );
};

export default StoreTransferListItem;
