import React from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const ProductListHeader = ({
  warehouseSelect,
  transferDisplay,
  isProdycDisplay,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <div
        style={{
          flex: 0.05,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      ></div>
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 5, width: "100%" }}> Sales Description:</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%", marginLeft: 5 }}> Sku:</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%", marginLeft: 5 }}>Category</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%", marginLeft: 5 }}>Sub-category</div>
      </div>

      {transferDisplay ? (
        <>
          <div
            style={{
              flex: 0.1,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 11,
              textAlign: "left",
            }}
          >
            <div style={{ marginLeft: 5, width: "95%" }}>Avaliable</div>
          </div>
          <div
            style={{
              flex: 0.1,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 11,
              textAlign: "left",
            }}
          >
            <div style={{ marginLeft: 5, width: "95%" }}>in-store</div>
          </div>
        </>
      ) : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            textAlign: "left",
          }}
        >
          <div style={{ marginLeft: 5, width: "95%" }}>Product Pref</div>
        </div>
      )}
      <div
        style={{
          flex: transferDisplay ? 0.125 : 0.05,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {transferDisplay ? "Stock" : "Size"}
      </div>
      {transferDisplay ? <div style={{ flex: 0.1 }} /> : null}
      <div
        style={{
          flex: transferDisplay ? 0.125 : 0.2,
          height: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ marginRight: 60 }}>
          {transferDisplay
            ? "Actions"
            : isProdycDisplay
            ? "Actions"
            : "Unit Measure"}
        </div>
      </div>
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          Retail
        </div>
      )}
      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.07,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          Margin
        </div>
      )}

      {warehouseSelect ? null : (
        <div
          style={{
            flex: 0.1,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60%",
              width: "100%",
              paddingRight: 10,
            }}
          >
            Actions
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductListHeader;
