import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import HeadersTitle from "../table-blocks/HeadersTitle";
import UserItem from "../table-blocks/UserItem";
import ExcelExport from "../ExcelExport";

const StoreUserPopUpDisplay = ({
  display,
  onCloseClick,
  frequentedStore,
  setLoading,
  setSelectedStoreName,
  selectedStoreName,
}) => {
  const [search, setSearch] = useState("");
  const [userArray, setUserArray] = useState([]);
  const [nameHasSplit, setNameHasSplit] = useState(null);

  const SplitName = () => {
    let name = selectedStoreName;
    const nameSplit = name.split(" ");
    if (nameSplit.length >= 3) {
      setNameHasSplit(nameSplit[1] + " " + nameSplit[2]);
      return nameSplit[1] + " " + nameSplit[2];
    } else {
      setNameHasSplit(nameSplit[1]);
      return nameSplit[1];
    }
  };

  const GetStoreUsers = async () => {
    setLoading(true);
    // setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/admin/getStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            frequentedStore:
              SplitName() === "Mossel" ? "Mossel Bay" : SplitName(),
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.success) {
        setLoading(false);
        setUserArray(respnseData.success);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStoreName) {
      SplitName();
    }
  }, [display]);
  useEffect(() => {
    GetStoreUsers();
  }, [nameHasSplit]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.4s",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          minHeight: 50,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: 60, fontWeight: "bold" }}>
          {selectedStoreName ? selectedStoreName.toLocaleUpperCase() : ""}
        </div>
        <div style={{ flex: 1 }} />
        <ExcelExport
          excelData={userArray ? userArray : []}
          fileName={"excel export"}
          title={"DOWNLOAD ALL"}
          mini={true}
          marginTop={-2.5}
          borderRadius={8}
          height={35}
        />
        <div style={{ minWidth: 20 }} />
        <div style={{ marginRight: 20 }}>
          User count : {userArray ? userArray.length : "0"}
        </div>

        {/* <div
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: 220,
            marginBottom: 5,
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 150,
              width: 300,

              borderWidth: 0.5,
            }}
          >
            <TextInputFieldSearch
              placeholder={"Type here to search"}
              height={25}
              borderRadius={4}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              mini={true}
            />
            <div
              style={{
                height: 30,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                onClick={() => setSearch("")}
                style={{ fontSize: 12, fontWeight: "bold" }}
              >
                CLEAR
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <HeadersTitle viewOnly={true} />
      <div
        style={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {userArray
          ? userArray.map((user, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: index === 0 ? 1 : 0,
                    marginBottom: index === userArray.length - 1 ? 60 : 0,
                  }}
                >
                  <UserItem
                    viewOnly={true}
                    firstName={user.firstName}
                    surname={user.lastName}
                    role={user.type}
                    store={user.dateCreated}
                    email={user.email}
                    cell={user.cellNumber}
                    status={user.status}
                    province={user.province}
                  />
                </div>
              );
            })
          : null}
      </div>

      <div style={{ position: "absolute", top: 5, left: 10 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
    </div>
  );
};

export default StoreUserPopUpDisplay;
