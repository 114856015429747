import React from "react";
import ProductListCard from "../product-list-card/ProductListCard";

const ProductListItem = ({
  display,
  data,
  loading,
  selectedProduct,
  setSelectedProd,
}) => {
  const NameDisplayAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        (product.thcType === "None" ? "" : product.thcType) +
        (product.thcType === "None" ? "" : " | ") +
        (product.flavour === "None" ? "" : product.flavour) +
        (product.flavour === "None" ? "" : " | ") +
        product.thcContent;
      return name;
    }
  };
  const WorkOutMargin = (product) => {
    const productBaseMargin =
      Number(product.retailPrice) - Number(product.costPrice);
    return productBaseMargin.toFixed(2);
  };
  return (
    <div
      style={{
        height: display ? null : 0,
        overflow: "hidden",
        width: "100%",
        transition: "all 0.3s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {data ? (
        data.length >= 1 ? (
          data.map((product, index) => {
            return (
              <div key={index} style={{ width: "100%" }}>
                <ProductListCard
                  productName={NameDisplayAssemble({ product })}
                  image={product.imageUrl}
                  sku={product.sku}
                  category={product.productType}
                  subCat={product.productSubType}
                  size={product.size}
                  unitMeasure={product.quantityDenomiation}
                  marginPrice={product ? WorkOutMargin(product) : "loading"}
                  productPref={product.flavour}
                  productFeature={product.strain}
                  retail={product.retailPriceInc}
                  onClick={() => setSelectedProd(product)}
                />
              </div>
            );
          })
        ) : (
          <div style={{ marginLeft: 20 }}>No Products yet</div>
        )
      ) : (
        "loading"
      )}
    </div>
  );
};

export default ProductListItem;
