import React, { useState, useEffect } from "react";
import {
  DashboardDisplayButton,
  DropDownDisplayArray,
  GeneratedReportCard,
  ModalLayout,
  RecieptItem,
  RecipetItemReportCard,
  StoreCard,
} from "./components";
import {
  CalenderSelector,
  LoadingPopUp,
  ToastNotification,
} from "../../../../components";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { Bar, Pie, Line, Stacked, Doughnut } from "react-chartjs-2";
import JsPDF from "jspdf";
import { Colors } from "chart.js";

const Reporting = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  selectedStore,
  auth,
  generatedReports,
}) => {
  const [receiptsArray, setReceiptsArray] = useState(null);

  const [refundArray, setRefundArray] = useState(null);
  const [exchangeArray, setExchangeArray] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");

  const [modalSelection, setModalSelection] = useState("");

  const [selectedReportsDisplay, setSelectedReportsDisplay] =
    useState("stores");

  const [selectedReportsList, setSelectedReportList] = useState("reports");

  const [startingDate, setStartingDate] = useState(new Date());

  const [startingDay, setStartingDay] = useState(
    new Date().toISOString().substring(8, 10)
  );
  const [startingMonth, setStartingMonth] = useState(
    new Date().toISOString().substring(5, 7)
  );
  const [startingYear, setStartingYear] = useState(
    new Date().toISOString().substring(0, 4)
  );

  const [dateSelectorPopUp, setDateSelectorPopUp] = useState(false);

  const [dayDropDown, setDayDropDown] = useState(false);
  const [monthDropDown, setMonthDropDown] = useState(false);
  const [yearDropDown, setYearDropDown] = useState(false);

  const [periodSelected, setPeriodSelected] = useState("1");

  // const [storeArray, setStoreArray] = useState(null);

  // const [selectedStore, setSelectedStore] = useState(null);

  const [productArray, setProductArray] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [refreshProduct, setRefreshProduct] = useState(false);

  const [cashregisterReport, setCashregisterReport] = useState(null);

  const [graphDisplayOption, setGraphDisplayOption] = useState("net sales");

  const [generatedReportView, setGeneratedReportView] = useState(null);

  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  const [netSales, setNetSales] = useState(0);
  const [netSalesEx, setNetSalesEx] = useState(0);
  const [refundsReport, setRefundReport] = useState(0);
  const [discountReport, setDiscountsReport] = useState(0);
  const [mjFee, setMjfee] = useState(0);
  const [prescriptionFee, setPresciption] = useState(0);
  const [cogReport, setCogReport] = useState(0);
  const [mjWholeSale, setMjWholeSaleReport] = useState(0);
  const [cashReport, setCashReport] = useState(0);
  const [cardReport, setCardReport] = useState(0);
  const [vatReport, setVatReport] = useState(0);
  const [tipsTotal, setTipsTotal] = useState(0);

  const [refreshHover, setRefreshHover] = useState(false);

  const [dateSelection, setDateSelection] = useState(null);

  const [salesToDate, setSalesTodate] = useState("loading...");
  const [salesToday, setSalesToday] = useState("loading...");
  const [sales7Days, setSales7Days] = useState("loading...");
  const [sales30Days, setSales30Days] = useState("loading...");

  const [storeUsers, setStoreUsers] = useState(null);

  const [periodSelectedDisplay, setPeriodSelectedDisplay] = useState(false);

  // here will be the selection and display of cash registers and receipts

  const [cashregisterDisplay, setCashregisterDisplay] = useState(false);
  const [selectedCashRegister, setSelectedCashRegister] = useState(null);

  // recipet display items

  const [selectedReceipt, setSelectedReceipt] = useState(null);

  const [selectedOptionDisplay, setSelectedOptionDisplay] =
    useState("reciepts");

  const [downloading, setDownloading] = useState(false);

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: auth.storeName,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setStoreUsers(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const createTipsArrayDisplay = ({ userId }) => {
    if (selectedCashRegister && selectedCashRegister.tipRecieptsArray) {
      let usersRest = [...selectedCashRegister.tipRecieptsArray];

      let valueArray = [];

      for (let index = 0; index < usersRest.length; index++) {
        const element = usersRest[index];

        usersRest.map((item) =>
          userId === element.cashierId
            ? valueArray.push(Number(item.amount))
            : null
        );
      }

      let tipsTotal = valueArray.reduce(function (x, y) {
        return x + y;
      }, 0);

      return tipsTotal;
    } else {
      return 0;
    }
  };

  const generatePDF = async () => {
    // prescriptionPdf();

    const report = await new JsPDF("portrait", "px", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.getFontList();

      // report.addFont(
      //   "ArialMS",
      //   "ArialMS-Bold",
      //   "Arial",
      //   "Arial-bold",
      //   "Courier-Bold",
      //   "Times",
      //   "Courier",
      //   "courier",
      //   "Helvetica",
      //   "Helvetica-Bold",
      //   "normal",
      //   "bold"
      // );
      // report.addFont(
      //   "Montserrat-Medium-normal.ttf",
      //   "Montserrat-Medium",
      //   "normal"
      // );
      // report.setFont("courier", "normal");
      // report.addFont(
      //   "Montserrat-Black-normal.ttf",
      //   "Montserrat-Black",
      //   "normal",
      //   "bold"
      // );
      report.save("report.pdf");
    });
    setDownloading(false);
  };

  const labels = () => {
    let labelsArray = [];
    let totalsArray = [];

    if (
      (cashregisterReport &&
        cashregisterReport.length >= 1 &&
        periodSelected === "7") ||
      periodSelected === "30"
    ) {
      labelsArray = [];
      for (let index = 0; index < cashregisterReport.length; index++) {
        const element = cashregisterReport[index];

        labelsArray.push(element.closedAt.substring(0, 10));
        totalsArray.push(element.TotalInc);
      }
      return {
        data: {
          labels: labelsArray,
          valuesArray: totalsArray,
        },
      };
    } else if (
      (receiptsArray && periodSelected === "1") ||
      periodSelected === "2"
    ) {
      labelsArray = [];
      for (let index = 0; index < receiptsArray.length; index++) {
        const element = receiptsArray[index];

        labelsArray.push(
          new Date(element.dateTime).toLocaleString().substring(10, 15)
        );
        totalsArray.push(element.total);
      }
    }
    return {
      data: {
        labels: labelsArray,
        valuesArray: totalsArray,
      },
    };
  };

  const data = {
    labels: labels().data.labels,
    datasets: [
      {
        label: "Sales report", // Setting up the label for the dataset
        labels: ["test"],
        backgroundColor: ["#08356C"], // Setting up the background color for the dataset
        borderColor: ["#08356C"], // Setting up the border color for the dataset
        data: labels().data.valuesArray,

        // Setting up the data for the dataset
      },
      // {
      //   label: "Sales report", // Setting up the label for the dataset
      //   labels: ["test"],
      //   backgroundColor: [
      //     "#48A14D",
      //     "#008080",
      //     "#B33F40",
      //     "#049BE5",
      //     "#9E3AC3",
      //   ], // Setting up the background color for the dataset
      //   borderColor: ["#48A14D", "#008080", "#B33F40", "#049BE5", "#9E3AC3"], // Setting up the border color for the dataset
      //   data: [
      //     11000, 3000, 350, 4090, 2000, 6000, 700, 12000, 800, 3000, 11000,
      //     3000, 350, 4090, 2000, 6000, 700, 12000, 800, 3000, 11000, 3000, 350,
      //     4090, 2000, 6000, 700, 12000, 800, 3000,
      //   ],

      //   // Setting up the data for the dataset
      // },
    ],
  };

  const getSalesReports = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStoreSalesReports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeId: selectedStore.storeId,
            startingDate: {
              day: Number(startingDay),
              month: Number(startingMonth),
              year: Number(startingYear),
            },
            period: periodSelected,
            type: "Owner",
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setNetSales(Number(respnseData.netSalesIncl));
        setNetSalesEx(Number(respnseData.netSalesEx));
        setRefundReport(Number(respnseData.refundsTotal));
        setDiscountsReport(Number(respnseData.discountsAmount));
        setMjfee(Number(respnseData.merryJaneServiceFee));
        setPresciption(Number(respnseData.prescriptionFee));
        setCogReport(Number(respnseData.Cog));
        setMjWholeSaleReport(Number(respnseData.merryJaneWholesaleEarnings));
        setCashReport(Number(respnseData.cashSales));
        setCardReport(Number(respnseData.cardSales));
        setVatReport(Number(respnseData.vat));
        setCashregisterReport(respnseData.registerReports);
        setReceiptsArray(respnseData.receiptList);
        setRefundArray(respnseData.refundList);
        setExchangeArray(respnseData.exchangeList);
        setDateSelection(respnseData.dateSelection);
        setTipsTotal(Number(respnseData.tipSaleTotal));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (auth) {
      GetStoreUsers();
    }
  }, []);

  useEffect(() => {
    if (auth && selectedStore) {
      getSalesReports();
    }
  }, [selectedStore, refresh, startingDate, periodSelected]);

  useEffect(() => {
    if (startingDate) {
      //this is the day check
      setStartingDay(startingDate.toISOString().substring(8, 10));

      //this is the month check
      setStartingMonth(startingDate.toISOString().substring(5, 7));

      //this is the year check
      setStartingYear(startingDate.toISOString().substring(0, 4));
    }
  }, [startingDate]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        flex: 1,

        display: "flex",
        justifyContent: "flex-start",
        overflowY: "hidden",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      {subSelection === "sales" ? (
        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            width: "100%",

            alignItems: "center",
          }}
        >
          <div style={{ flex: 1 }} />
          <div
            style={{
              position: "fixed",
              marginLeft: "10%",
              top: 0,
              zIndex: 90000000000000,
            }}
          >
            <img
              onMouseOver={() => setRefreshHover(true)}
              onMouseOut={() => setRefreshHover(false)}
              onClick={
                modalSelection === "products"
                  ? () => setRefreshProduct(refreshProduct ? false : true)
                  : () => setRefresh(refresh ? false : true)
              }
              alt="icon"
              src={require("../../../../images/material-icons/refreshwhite.png")}
              style={{
                height: refreshHover ? 40 : 40,
                // backgroundColor: BlackAndWhite.primary,
                borderRadius: 50,
                marginTop: 10,
                transform: loading
                  ? `rotate(360deg)`
                  : refreshHover
                  ? `rotate(0deg)`
                  : `rotate(360deg)`,
                transition: loading ? "transform 3s" : "all 0.7s",
              }}
            />
            {/* <BaseButtons
              label={"REFRESH"}
              mini={true}
              marginTop={5}
              borderRadius={8}
              marginRight={10}
              minHeight={40}
              height={40}
              size={90}
              invert={true}
              // backgroundColor={BlackAndWhite.secondary}
              // fontColor={BlackAndWhite.secondary}
              onClick={
                modalSelection === "products"
                  ? () => setRefreshProduct(refreshProduct ? false : true)
                  : () => setRefresh(refresh ? false : true)
              }
            /> */}
          </div>
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 20, display: "flex" }}>
              {dateSelectorPopUp ? (
                <CalenderSelector
                  startingDate={startingDate}
                  setStartingDate={setStartingDate}
                  setDateSelectorPopUp={setDateSelectorPopUp}
                />
              ) : (
                <div>
                  <div
                    style={{
                      fontSize: 10,
                      opacity: 0.6,
                      fontWeight: "bold",
                      width: "100%",
                      marginBottom: 3,
                    }}
                  >
                    DATE SELECTOR
                  </div>
                  <BaseButtons
                    label={startingDate.toDateString()}
                    mini={true}
                    minHeight={30}
                    height={30}
                    borderRadius={8}
                    marginTop={5}
                    disable={
                      periodSelected === "1" || periodSelected === "2"
                        ? true
                        : false
                    }
                    onClick={
                      periodSelected === "1" || periodSelected === "2"
                        ? null
                        : () => setDateSelectorPopUp(true)
                    }
                  />
                </div>
              )}
            </div>
            <div
              style={{
                position: "relative",
                marginBottom: 35,
                marginRight: 60,
              }}
            >
              <div
                style={{
                  fontSize: 10,
                  opacity: 0.6,
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: 3,
                }}
              >
                PERIOD
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: !periodSelectedDisplay ? null : "hidden",
                  height: !periodSelectedDisplay ? 100 : 0,
                  position: "absolute",
                  marginTop: 4,
                  marginBottom: 20,
                  // backgroundColor: BlackAndWhite.secondary,
                }}
              >
                <BaseButtons
                  label={
                    periodSelected === "1"
                      ? "Today"
                      : periodSelected === "2"
                      ? "Yesterday"
                      : periodSelected === "7"
                      ? "7 days"
                      : periodSelected === "30"
                      ? "30 days"
                      : null
                  }
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={100}
                  invert={false}
                  onClick={() => {
                    setPeriodSelectedDisplay(true);
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  height: periodSelectedDisplay ? null : 0,
                  backgroundColor: BlackAndWhite.secondary,
                  zIndex: 99999999999,
                  position: "absolute",
                  alignItems: "center",
                  padding: periodSelectedDisplay ? 5 : 0,
                  borderRadius: 4,
                  WebkitBoxShadow: periodSelectedDisplay
                    ? "0px 0px 7px 2px #00000050"
                    : "0px 0px 0px 0px #00000050",
                }}
              >
                <BaseButtons
                  label={"TODAY"}
                  mini={true}
                  marginTop={5}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "1" ? false : true}
                  onClick={() => {
                    setPeriodSelected("1");
                    setStartingDate(new Date());
                    setPeriodSelectedDisplay(false);
                  }}
                />
                <BaseButtons
                  label={"YESTERDAY"}
                  mini={true}
                  marginTop={5}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={120}
                  invert={periodSelected === "2" ? false : true}
                  onClick={() => {
                    setPeriodSelected("2");
                    setStartingDate(new Date());
                    setPeriodSelectedDisplay(false);
                  }}
                />
                <BaseButtons
                  label={"7 DAYS"}
                  mini={true}
                  marginTop={5}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "7" ? false : true}
                  onClick={() => {
                    setPeriodSelected("7");
                    setPeriodSelectedDisplay(false);
                  }}
                />
                <BaseButtons
                  label={"30 DAYS"}
                  mini={true}
                  marginTop={5}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "30" ? false : true}
                  onClick={() => {
                    setPeriodSelected("30");
                    setPeriodSelectedDisplay(false);
                  }}
                />
                {/* <BaseButtons
                  label={"YEAR"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "365" ? false : true}
                  onClick={() => setPeriodSelected("365")}
                /> */}
              </div>
            </div>
          </div>
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              // marginRight: 20,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              {/* <div
                style={{
                  fontSize: 10,
                  opacity: 0.6,
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: 3,
                }}
              >
                {selectedReportsDisplay === "products"
                  ? "SELECT PRODUCT"
                  : "SELECT STORE"}
              </div> */}

              {selectedReportsDisplay === "products" ? (
                <BaseButtons
                  label={
                    selectedProduct
                      ? selectedProduct.productName
                      : "SELECT PRODUCT"
                  }
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={180}
                  onClick={() => setModalSelection("products")}
                />
              ) : null}
              {/* <BaseButtons
                label={selectedStore ? selectedStore.storeName : "SELECT STORE"}
                mini={true}
                marginTop={-1}
                borderRadius={8}
                // marginRight={10}
                minHeight={30}
                height={30}
                size={240}
              /> */}
            </div>
          </div>
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"STORES"}
              mini={true}
              marginTop={5}
              borderRadius={8}
              marginRight={10}
              minHeight={35}
              height={40}
              size={100}
              invert={selectedReportsDisplay === "stores" ? false : true}
              onClick={() => setSelectedReportsDisplay("stores")}
            />{" "}
            <BaseButtons
              borderRadius={8}
              label={"PRODUCT"}
              mini={true}
              marginTop={5}
              minHeight={35}
              height={40}
              disable={true}
              size={100}
              // invert={selectedReportsDisplay === "products" ? false : true}
              onClick={() => setSelectedReportsDisplay("products")}
            />
          </div>
        </div>
      ) : null}

      {subSelection === "sales" ? (
        <div
          style={{
            // minHeight: 500,
            // maxHeight: 500,
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            overflowY: "scroll",
            overflowX: "hidden",
            flexDirection: "column",
            scrollbarWidth: "none",
          }}
        >
          <div
            style={{
              width: "100%",

              flex: 0.7,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              overflow: "hidden",
              minHeight: 710,
            }}
          >
            {/* // report number display */}

            <div
              style={{
                flex: 1,
                height: "100%",
                backgroundColor: BlackAndWhite.secondary,

                borderRadius: 10,
                marginLeft: 25,
                marginRight: 25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                  marginTop: 20,
                  paddingBottom: 20,
                  paddingTop: 20,
                  borderRadius: 8,
                  minHeight: 500,
                  maxHeight: 500,
                }}
              >
                {/* header options */}
                <div
                  style={{
                    height: 65,

                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={35}
                    marginTop={-1}
                    label={"SALES "}
                    marginRight={10}
                    size={110}
                    marginLeft={20}
                  />
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={35}
                    marginTop={-1}
                    label={"PRODUCTS"}
                    marginRight={10}
                    size={110}
                    marginLeft={10}
                    disable={true}
                  />
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={35}
                    marginTop={-1}
                    label={"DAYS"}
                    marginRight={10}
                    size={110}
                    marginLeft={20}
                    disable={true}
                  />

                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={35}
                    marginTop={-1}
                    label={"TIME"}
                    marginRight={10}
                    size={110}
                    marginLeft={20}
                    disable={true}
                  />
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={35}
                    marginTop={-1}
                    label={"TEAM"}
                    marginRight={10}
                    size={110}
                    marginLeft={20}
                    disable={true}
                  />
                </div>
                {/* graph options displayed */}
                <div
                  style={{
                    width: "95%",
                    marginTop: 10,
                    flex: 1,
                    marginLeft: 20,
                  }}
                >
                  <Bar
                    // width={100}
                    height={75}
                    //   options={}
                    data={data}
                    // style={{ height: 300, width: "20%" }}
                  />
                </div>
              </div>
              <div
                style={{
                  // height: "92%",
                  minWidth: 350,
                  width: "100%",
                  backgroundColor: BlackAndWhite.secondary,
                  WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                  borderRadius: 10,
                  // marginLeft: 25,
                  // minHeight: 680,
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                  marginTop: 40,
                  marginBottom: 30,
                  minHeight: 250,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{ marginLeft: 10, fontSize: 18, fontWeight: "bold" }}
                  >
                    REPORT SUMMARY
                  </div>
                  <div style={{ flex: 1 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      marginLeft: 10,
                      fontSize: 12,
                      fontWeight: "normal",
                      marginTop: 10,
                    }}
                  >
                    DATE:{" "}
                    {dateSelection
                      ? dateSelection.substring(14, 24) +
                        " to " +
                        dateSelection.substring(0, 10)
                      : "Loading"}
                  </div>
                  <div style={{ flex: 1 }} />
                  <div
                    style={{
                      width: 160,
                      // borderLeftStyle: "solid",
                      // borderRightStyle: "solid",
                      marginRight: 10,
                      textAlign: "center",
                      marginTop: 10,
                      fontWeight: "bold",
                      fontSize: 12,
                    }}
                  >
                    SUMMARY
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flex: 1,
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <div style={{ flex: 0.33, height: "100%" }}>
                    {/* net sales */}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        {" "}
                        Net sales ( incl vat )
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {netSales ? netSales.toFixed(2) : " 0.00"}
                        </div>
                      </div>
                    </div>
                    {/* net sales  ex vat*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Net sales ( Ex vat )
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />
                        <div style={{ marginRight: 10 }}>
                          {" "}
                          {netSales && netSalesEx
                            ? (netSales - netSalesEx).toFixed(2)
                            : " 0.00"}
                        </div>
                      </div>
                    </div>
                    {/* refunds incl vat*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Refunds ( Incl vat )
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {refundsReport ? refundsReport.toFixed(2) : " 0.00"}
                        </div>
                      </div>
                    </div>
                    {/* discount incl vat*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Discounts ( Incl vat )
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {discountReport ? discountReport.toFixed(2) : " 0.00"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 0.33, height: "100%" }}>
                    {/* less refubds total sales incl vat*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Net sales ( incl ) total less refunds
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {netSales
                            ? (netSales - refundsReport).toFixed(2)
                            : " 0.00"}
                        </div>
                      </div>
                    </div>

                    {/* less refubds total sales ex vat*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Net sales ( Ex ) total less refunds
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {netSales
                            ? (netSales - netSalesEx - refundsReport).toFixed(2)
                            : " 0.00"}
                        </div>
                      </div>
                    </div>

                    {/* mj wholes sale value*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Wholesale COG
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {mjWholeSale
                            ? Number(mjWholeSale + cogReport).toFixed(2)
                            : " 0.00"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 0.33, height: "100%" }}>
                    {/* Card transactions merry jane holdings (PTY LTD*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Card trans Merry jane holdings
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {cardReport ? cardReport.toFixed(2) : " 0.00"}
                        </div>
                      </div>
                    </div>

                    {/* cash recieved in-store*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Cash recieved in-store
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>{" "}
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {cashReport ? cashReport.toFixed(2) : " 0.00"}
                        </div>
                      </div>
                    </div>

                    {/* tips premiumin-store*/}
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 3,
                      }}
                    >
                      <div style={{ marginLeft: 10, fontSize: 16 }}>
                        Tips total
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          width: 160,
                          // borderLeftStyle: "solid",
                          // borderRightStyle: "solid",
                          marginRight: 12,
                          textAlign: "center",
                          fontSize: 16,
                          backgroundColor: "#00000030",
                          paddingTop: 5,
                          paddingBottom: 5,
                          display: "flex",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}> R</div>
                        <div style={{ flex: 1 }} />{" "}
                        <div style={{ marginRight: 10 }}>
                          {tipsTotal ? Number(tipsTotal).toFixed(2) : "0.00"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              // borderStyle: "solid",
              minHeight: 750,
              height: 750,
              marginBottom: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* report list */}
            <div
              style={{
                height: "95%",

                width: "97%",
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                borderRadius: 10,
                // marginLeft: 25,
                minHeight: 400,
                marginLeft: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 50,

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      // width: 300,

                      borderWidth: 0.5,
                    }}
                  >
                    <TextInputFieldSearch
                      placeholder={"Type here to search"}
                      height={25}
                      borderRadius={4}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      fontSize={14}
                    />
                    <div
                      style={{
                        height: 30,
                        width: 70,
                        backgroundColor: BlackAndWhite.primary,
                        borderRadius: 4,
                        display: "flex",
                        color: BlackAndWhite.secondary,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => setSearch("")}
                        style={{ fontSize: 12, fontWeight: "bold" }}
                      >
                        CLEAR
                      </div>
                    </div>
                    <div
                      style={{
                        height: 30,
                        width: 70,
                        backgroundColor: BlackAndWhite.primary,
                        borderRadius: 4,
                        display: "flex",
                        color: BlackAndWhite.secondary,
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <div
                        onClick={() => setSearch("")}
                        style={{ fontSize: 12, fontWeight: "bold" }}
                      >
                        SEARCH
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} />

                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={30}
                  marginTop={-1}
                  label={"WEEKLY REPORTS"}
                  marginRight={10}
                  size={200}
                  // marginLeft={10}
                  onClick={() => setSelectedReportList("generatedReports")}
                  invert={
                    selectedReportsList === "generatedReports" ? false : true
                  }
                />

                {selectedReportsDisplay === "stores" ? (
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={30}
                    marginTop={-1}
                    label={"REGISTERS"}
                    marginRight={10}
                    size={110}
                    // marginLeft={10}
                    onClick={() => setSelectedReportList("reports")}
                    invert={selectedReportsList === "reports" ? false : true}
                  />
                ) : null}
              </div>
              <div
                style={{
                  flex: 1,

                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                }}
              >
                {selectedReportsList === "reports" &&
                selectedReportsDisplay === "stores"
                  ? cashregisterReport
                    ? cashregisterReport.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedCashRegister(item);
                              setCashregisterDisplay(true);
                            }}
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <GeneratedReportCard
                              date={item.openedBy}
                              time={item.closedBy}
                              slipNo={
                                item.openedAt.substring(0, 10) +
                                " at " +
                                item.openedAt.substring(11, 16) +
                                " TO " +
                                item.closedAt.substring(0, 10) +
                                " at " +
                                item.closedAt.substring(11, 16)
                              }
                              // cashier={"1"}
                              // customerName={"2"}
                              total={"R " + Number(item.TotalInc).toFixed(2)}
                            />
                          </div>
                        );
                      })
                    : null
                  : null}

                {selectedReportsList === "generatedReports" &&
                selectedReportsDisplay === "stores"
                  ? generatedReports
                    ? generatedReports.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "95%",
                                minHeight: 80,
                                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                                borderRadius: 8,
                                backgroundColor: BlackAndWhite.secondary,
                                alignItems: "center",
                              }}
                            >
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Count:
                                </div>
                                <div> {index + 1}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Type:
                                </div>
                                <div> {item.reportType}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Date covered:
                                </div>

                                <div> {item.dateFromTo}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Time frame:
                                </div>

                                <div> {item.period} days</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  No of sales:
                                </div>

                                <div> {item.receiptsArray.length}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  No. of refunds:
                                </div>

                                <div> {item.refundsArray.length}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Created by:
                                </div>

                                <div> {item.requestedBy}</div>
                              </div>

                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Total sales:
                                </div>

                                <div>R {item.netSalesIncludingVat}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Total services:
                                </div>

                                <div>R {item.totalServices}</div>
                              </div>
                              <div style={{ flex: 1 }} />
                              <BaseButtons
                                mini={true}
                                borderRadius={4}
                                height={40}
                                marginTop={-1}
                                label={"VIEW"}
                                marginRight={20}
                                size={110}
                                fontWeight={"bold"}
                                onClick={() => setGeneratedReportView(item)}
                                // marginLeft={10}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}

                <div style={{ minHeight: 20 }} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <LoadingPopUp display={loading} newWidth={"100%"} /> */}

      <ModalLayout
        display={modalSelection === "products" ? true : false}
        page={
          <div
            style={{
              height: "80%",
              width: "80%",
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 20,
              WebkitBoxShadow: "0px 0px 7px 2px #00000050",
              minHeight: 600,
              minWidth: 650,
              maxWidth: 600,
              display: "flex",
              position: "relative",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton label={"X"} onClick={() => setModalSelection("")} />
            </div>
            <div
              style={{
                width: "100%",
                height: 70,

                display: "flex",
                alignItems: "center",
                WebkitBoxShadow: "0px -2px 7px 2px #00000050",
              }}
            >
              <div style={{ marginLeft: 80, fontSize: 18, fontWeight: "bold" }}>
                PRODUCT SELECTION
              </div>
              <div style={{ flex: 1 }} />
              <div style={{ marginLeft: 10, marginRight: 20 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    fontSize={14}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,

                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {productArray
                ? productArray.map((product, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <div
                          onClick={() => {
                            setSelectedProduct(product);
                            setModalSelection("");
                          }}
                          style={{
                            width: "90%",
                            minHeight: 100,
                            borderRadius: 8,
                            backgroundColor: BlackAndWhite.secondary,
                            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                          }}
                        >
                          {product.productName}
                        </div>
                      </div>
                    );
                  })
                : null}

              <div style={{ minHeight: 20 }} />
            </div>
          </div>
        }
      />
      <ModalLayout
        display={generatedReportView}
        page={
          <div
            style={{
              minHeight: 656,
              minWidth: 600,
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 20,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CloseButton
                label={"X"}
                onClick={() => setGeneratedReportView(null)}
              />
              <div style={{ marginLeft: 20, fontSize: 18, fontWeight: "bold" }}>
                {generatedReportView ? generatedReportView.reportType : null}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 20,
                right: 30,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: 4,
                  fontSize: 10,
                  fontWeight: "bold",
                  opacity: 0.6,
                  width: "100%",
                }}
              >
                DATE REQUESTED:
              </div>
              <div>
                {generatedReportView
                  ? generatedReportView.dateRequested.substring(0, 21)
                  : null}
              </div>
            </div>
            <div style={{ minHeight: 60, width: "100%" }}></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  marginLeft: 25,
                  fontSize: 16,
                  fontWeight: "normal",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    marginBottom: 4,
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.6,
                    width: "100%",
                  }}
                >
                  PERIOD:
                </div>
                <div>
                  {" "}
                  {generatedReportView ? generatedReportView.dateFromTo : null}
                </div>
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  // borderTopStyle: "solid",
                  // borderBottomStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: BlackAndWhite.primary,
                  color: BlackAndWhite.secondary,
                }}
              >
                SUMMARY
              </div>
            </div>

            {/* net sales */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                {" "}
                Net sales ( incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesIncludingVat
                  : null}
              </div>
            </div>
            {/* net sales  ex vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( Ex vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesExcludingVat
                  : null}
              </div>
            </div>
            {/* refunds incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Refunds ( Incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.refunds : null}
              </div>
            </div>
            {/* discount incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Discounts ( Incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.discounts : null}
              </div>
            </div>

            {/* less refubds total sales incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( incl ) total less refunds
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesInclLessRefunds
                  : null}
              </div>
            </div>

            {/* less refubds total sales ex vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( Ex ) total less refunds
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesExLessRefunds
                  : null}
              </div>
            </div>

            {/* Merry-Jane service fee*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Merry-Jane service fee ( 15% )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.merryJaneServiceFee
                  : null}
              </div>
            </div>

            {/* prescription fee service fee*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Prescription fee ( 1% )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.prescriptionFee
                  : null}
              </div>
            </div>

            {/* cost of goods sold value*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Cost of goods</div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.wholesalecostOfGoodsSold
                  : null}
              </div>
            </div>
            {/* Card transactions merry jane holdings (PTY LTD*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Card trans Merry jane holdings
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.cardTransactions
                  : null}
              </div>
            </div>

            {/* cash recieved in-store*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Cash recieved in-store
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.cashReceivedInstore
                  : null}
              </div>
            </div>

            {/* tips toatl premiumin-store*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Tips total</div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.tipsTotal
                    ? generatedReportView.tipsTotal
                    : "0.00"
                  : "0.00"}
              </div>
            </div>

            {/*  Total deductions*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Total deductions
              </div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.totalDeductions
                  : null}
              </div>
            </div>
            {/*    Amount due*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Amount due</div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.AmountDue : null}
              </div>
            </div>
            {/*    Total services*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Total services</div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderBottomRightRadius: 15,
                }}
              >
                R{" "}
                {generatedReportView ? generatedReportView.totalServices : null}
              </div>
            </div>
          </div>
        }
      />
      <ModalLayout
        mobile={true}
        display={cashregisterDisplay}
        page={
          <div
            style={{
              minHeight: 656,
              minWidth: 600,
              backgroundColor: BlackAndWhite.secondary,
              // borderRadius: 20,
              position: "relative",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              height: "100%",
              width: "100%",
              zIndex: 900000000000000,
            }}
          >
            <div
              style={{
                flex: 0.6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: selectedCashRegister ? "100%" : 0,
                  backgroundColor: BlackAndWhite.secondary,
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  top: 0,
                  left: 0,
                  zIndex: 10000000000000,
                  justifyContent: "center",
                  alignItems: "center",
                  // fontFamily: "Arial",
                  scrollbarWidth: "none",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CloseButton
                    label={"X"}
                    invert={false}
                    onClick={() => {
                      setCashregisterDisplay(false);
                      setSelectedCashRegister(null);
                    }}
                  />
                  <div
                    style={{
                      marginLeft: 10,
                      fontWeight: "normal",
                      fontSize: 16,
                      display: "flex",
                    }}
                  >
                    CASH REGISTER REPORT FOR{" "}
                    <div style={{ marginLeft: 5, fontWeight: "bold" }}>
                      {selectedCashRegister
                        ? selectedCashRegister.openedAt.substring(0, 10) +
                          " / " +
                          selectedCashRegister.openedAt.substring(11, 16) +
                          " TO " +
                          selectedCashRegister.closedAt.substring(0, 10) +
                          " / " +
                          selectedCashRegister.closedAt.substring(11, 16)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 20,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    mini={true}
                    label={"DOWNLOAD"}
                    marginTop={-1}
                    //   invert={true}
                    height={40}
                    borderRadius={8}
                    onClick={() => generatePDF()}
                  />
                </div>
                <div style={{ minHeight: 60 }}></div>
                <div
                  style={{
                    display: "flex",

                    flex: 1,
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      flex: 1,

                      height: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      flexDirection: "column",
                      backgroundColor: BlackAndWhite.secondary,
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{
                        height: "98%",
                        borderTopRightRadius: 10,
                        width: "100%",
                        // WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          marginTop: 20,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 10,
                              opacity: 0.6,
                              marginBottom: 5,
                            }}
                          >
                            OPENED BY:
                          </div>
                          <div>
                            {selectedCashRegister
                              ? selectedCashRegister.openedBy
                              : null}
                          </div>
                        </div>

                        <div style={{ flex: 1 }} />
                        <div>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 10,
                              opacity: 0.6,
                              marginBottom: 5,
                            }}
                          >
                            CLOSED BY:
                          </div>
                          <div>
                            {selectedCashRegister
                              ? selectedCashRegister.closedBy
                              : null}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          marginTop: 20,
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "bold",
                        }}
                      >
                        <div style={{ fontWeight: "bold" }}>PAYMENT TYPES:</div>
                        <div style={{ flex: 1 }} />
                        <div
                          style={{
                            width: 100,
                            textAlign: "right",
                            fontSize: 14,
                          }}
                        >
                          EXPECTED
                        </div>
                        <div
                          style={{
                            width: 100,
                            textAlign: "right",
                            fontSize: 14,
                          }}
                        >
                          COUNTED
                        </div>
                        <div
                          style={{
                            width: 100,
                            textAlign: "right",
                            fontSize: 14,
                          }}
                        >
                          DIFF.
                        </div>
                      </div>
                      {/* cash net sales */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{}}>Cash (net cash payments): </div>
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          {" "}
                          {selectedCashRegister
                            ? "R" +
                              (
                                Number(selectedCashRegister.cash) +
                                Number(selectedCashRegister.tillOpeningAmount)
                              ).toFixed(2)
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          {" "}
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.cashCounted).toFixed(
                                2
                              )
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          {" "}
                          {selectedCashRegister
                            ? "R" +
                              (
                                (
                                  Number(selectedCashRegister.cash) +
                                  Number(selectedCashRegister.tillOpeningAmount)
                                ).toFixed(2) -
                                Number(
                                  selectedCashRegister.cashCounted
                                ).toFixed(2)
                              ).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* card */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{}}>Card: </div>
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" + Number(selectedCashRegister.card).toFixed(2)
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.cardCounted).toFixed(
                                2
                              )
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              (
                                Number(selectedCashRegister.card) -
                                Number(selectedCashRegister.cardCounted)
                              ).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* credit */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{}}>Store credit: </div>
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.storeCredit).toFixed(
                                2
                              )
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.storeCredit).toFixed(
                                2
                              )
                            : null}
                        </div>
                        <div style={{ width: 100, textAlign: "right" }}>
                          R0.00
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          borderStyle: "solid",
                          marginTop: 15,
                          borderWidth: 0.5,
                          opacity: 0.5,
                          borderRadius: 8,
                        }}
                      />
                      {/* payment toaals */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          Payments
                        </div>
                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.TotalInc).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* refund totals */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          Refunds
                        </div>
                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "- R" +
                              Number(selectedCashRegister.refunds).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* net receipts */}
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ flex: 1 }} />
                        <div style={{ width: 100, textAlign: "right" }}>
                          Net receipts
                        </div>
                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              (
                                Number(selectedCashRegister.TotalInc) -
                                Number(selectedCashRegister.refunds)
                              ).toFixed(2)
                            : null}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          borderStyle: "solid",
                          marginTop: 15,
                          borderWidth: 0.5,
                          opacity: 0.5,
                          borderRadius: 8,
                        }}
                      />

                      {/* Total (Incl) Sales: */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 100, textAlign: "left" }}>
                          Total (Incl) Sales:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R " +
                              Number(selectedCashRegister.TotalInc).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* Total (Ex) Sales: */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 100, textAlign: "left" }}>
                          Total (Ex) Sales:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              (
                                Number(selectedCashRegister.TotalInc) -
                                Number(selectedCashRegister.vat)
                              ).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* Total Vat */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 100, textAlign: "left" }}>
                          Total Vat:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R " + Number(selectedCashRegister.vat).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* Discounts: */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 100, textAlign: "left" }}>
                          Discounts:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.discounts).toFixed(2)
                            : null}
                        </div>
                      </div>
                      {/* Till Opening Amt.:: */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 100, textAlign: "left" }}>
                          Till Opening Amt.:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(
                                selectedCashRegister.tillOpeningAmount
                              ).toFixed(2)
                            : null}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          borderStyle: "solid",
                          marginTop: 15,
                          borderWidth: 0.5,
                          opacity: 0.5,
                          borderRadius: 8,
                        }}
                      />
                      {/* transactions.:: */}

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 150, textAlign: "left" }}>
                          Number of Transactions:
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          Average Transaction
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          borderStyle: "solid",
                          marginTop: 15,
                          borderWidth: 0.5,
                          opacity: 0.5,
                          borderRadius: 8,
                        }}
                      />

                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 150, textAlign: "left" }}>
                          {selectedCashRegister
                            ? selectedCashRegister.receiptsArray.length
                            : null}
                        </div>
                        <div style={{ flex: 1 }} />

                        <div style={{ width: 200, textAlign: "right" }}>
                          {selectedCashRegister
                            ? "R" +
                              Number(selectedCashRegister.averageSale).toFixed(
                                2
                              )
                            : null}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          marginTop: 15,
                          display: "flex",
                          alignItems: "center",
                          fontSize: 12,
                        }}
                      >
                        <div style={{ width: 150, textAlign: "left" }}>
                          STORE CASHIERS TIPS
                        </div>
                        <div style={{ flex: 1 }} />
                        <div>AMOUNT DUE</div>
                      </div>
                      <div
                        style={{
                          width: "90%",
                          borderStyle: "solid",
                          marginTop: 15,
                          borderWidth: 0.5,
                          opacity: 0.5,
                          borderRadius: 8,
                        }}
                      />
                      {storeUsers
                        ? storeUsers.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  width: "90%",
                                  marginTop: 15,
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: 12,
                                }}
                              >
                                <div style={{ width: 150, textAlign: "left" }}>
                                  {item
                                    ? item.firstName + " " + item.lastName
                                    : null}
                                </div>
                                <div style={{ flex: 1 }} />

                                <div style={{ width: 200, textAlign: "right" }}>
                                  {item && selectedCashRegister
                                    ? "R" +
                                      createTipsArrayDisplay({
                                        userId: item._id,
                                      }).toFixed(2)
                                    : null}
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1,

                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                          maxWidth: 450,
                        }}
                      >
                        <div
                          // key={selectedReportIndex}
                          id="report"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: 450,
                            position: "relative",
                            minHeight: 625,
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 20,
                              left: 20,
                              display: "flex",
                              flexDirection: "column",
                              fontSize: 12,
                            }}
                          >
                            CASH REGISTER{" "}
                            {/* {selectedReportIndex ? selectedReportIndex : null} */}
                            <div style={{ fontSize: downloading ? 14 : 10 }}>
                              {selectedCashRegister
                                ? selectedCashRegister.storeName
                                : null}
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 20,
                              left: 210,

                              display: "flex",
                              flexDirection: "column",
                              textAlign: "right",
                              fontSize: 12,
                            }}
                          >
                            REGISTER SUMMARY{" "}
                            {/* {selectedReportIndex ? selectedReportIndex : null} */}
                            <div style={{ fontSize: 10 }}>
                              {selectedCashRegister
                                ? selectedCashRegister.openedAt.substring(
                                    0,
                                    10
                                  ) +
                                  " - " +
                                  selectedCashRegister.openedAt.substring(
                                    11,
                                    19
                                  ) +
                                  "  TO  "
                                : null}

                              {selectedCashRegister
                                ? selectedCashRegister.closedAt.substring(
                                    0,
                                    10
                                  ) +
                                  " - " +
                                  selectedCashRegister.closedAt.substring(
                                    11,
                                    19
                                  )
                                : null}
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 80,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "105%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 10,
                                  flex: 1,
                                }}
                              >
                                <div style={{ marginLeft: 10 }}>
                                  {" "}
                                  Payment Type
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 10,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                <div style={{ marginLeft: 3 }}>Expected</div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 10,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                <div style={{ marginLeft: 3 }}>Counted</div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 10,
                                  width: 60,
                                  textAlign: "right",
                                  paddingLeft: 3,
                                }}
                              >
                                <div style={{ marginLeft: 3 }}>Difference</div>
                              </div>
                            </div>

                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 105,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              >
                                Cash (net cash payments)
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    (
                                      Number(selectedCashRegister.cash) +
                                      Number(
                                        selectedCashRegister.tillOpeningAmount
                                      )
                                    ).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.cashCounted
                                    ).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    (
                                      (
                                        Number(selectedCashRegister.cash) +
                                        Number(
                                          selectedCashRegister.tillOpeningAmount
                                        )
                                      ).toFixed(2) -
                                      Number(
                                        selectedCashRegister.cashCounted
                                      ).toFixed(2)
                                    ).toFixed(2)
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 125,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              >
                                Card
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(selectedCashRegister.card).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.cardCounted
                                    ).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    (
                                      Number(selectedCashRegister.card) -
                                      Number(selectedCashRegister.cardCounted)
                                    ).toFixed(2)
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 145,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              >
                                Credit
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.storeCredit
                                    ).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.storeCredit
                                    ).toFixed(2)
                                  : null}
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 60,
                                  textAlign: "right",
                                }}
                              >
                                R0.00
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 165,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 175,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                }}
                              >
                                Payments:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.TotalInc
                                    ).toFixed(2)
                                  : null}
                                <div
                                  style={{
                                    position: "absolute",

                                    minHeight: 1,
                                    width: 120,
                                    bottom: -5,
                                    backgroundColor: "#C0C0C1",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 195,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                }}
                              >
                                Refunds:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "- R" +
                                    Number(
                                      selectedCashRegister.refunds
                                    ).toFixed(2)
                                  : null}
                                <div
                                  style={{
                                    position: "absolute",

                                    minHeight: 1,
                                    width: 120,
                                    bottom: -5,
                                    backgroundColor: "#C0C0C1",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 215,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  width: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                }}
                              >
                                Net receipts:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    (
                                      Number(selectedCashRegister.TotalInc) -
                                      Number(selectedCashRegister.refunds)
                                    ).toFixed(2)
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 230,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 240,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  minWidth: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                }}
                              >
                                Total (Incl) Sales:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.TotalInc
                                    ).toFixed(2)
                                  : null}
                                {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 260,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  minWidth: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                Total (Ex) Sales: <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    (
                                      Number(selectedCashRegister.TotalInc) -
                                      Number(selectedCashRegister.vat)
                                    ).toFixed(2)
                                  : null}
                                {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 280,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  minWidth: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                Total Vat:{" "}
                                <div style={{ fontSize: 7, marginLeft: 3 }}>
                                  {selectedCashRegister
                                    ? "(R" +
                                      Number(
                                        selectedCashRegister.TotalInc
                                      ).toFixed(2) +
                                      ")"
                                    : null}
                                </div>
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    Number(selectedCashRegister.vat).toFixed(2)
                                  : null}
                                {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 300,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  minWidth: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                Discounts:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.discounts
                                    ).toFixed(2)
                                  : null}
                                {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 320,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              ></div>

                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,
                                  minWidth: 120,
                                  textAlign: "right",
                                  display: "flex",
                                  flexDirection: "row",
                                  position: "relative",
                                  alignItems: "center",
                                }}
                              >
                                Till Opening Amt.:
                                <div style={{ flex: 1 }} />
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.tillOpeningAmount
                                    ).toFixed(2)
                                  : null}
                                {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 340,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 380,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,
                                  flex: 1,
                                  display: "flex",
                                }}
                              >
                                <div>Transaction</div>
                                <div
                                  style={{ marginLeft: 40, fontWeight: "bold" }}
                                >
                                  User
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,

                                  display: "flex",
                                }}
                              >
                                Action
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 380,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 400,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 7,
                                  flex: 1,
                                  display: "flex",
                                }}
                              >
                                <div>Opening float</div>
                                <div style={{ marginLeft: 40 }}>
                                  {selectedCashRegister
                                    ? selectedCashRegister.openedBy
                                    : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "normal",
                                  fontSize: 8,

                                  display: "flex",
                                }}
                              >
                                +{" "}
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.tillOpeningAmount
                                    ).toFixed(2)
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 420,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 340,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,
                                  flex: 1,
                                  display: "flex",
                                }}
                              >
                                <div>Num. of transctions</div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,

                                  display: "flex",
                                }}
                              >
                                Average Sale
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 360,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 360,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,
                                  flex: 1,
                                  display: "flex",
                                }}
                              >
                                <div>
                                  {selectedCashRegister
                                    ? selectedCashRegister.receiptsArray.length
                                    : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,

                                  display: "flex",
                                }}
                              >
                                {selectedCashRegister
                                  ? "R" +
                                    Number(
                                      selectedCashRegister.averageSale
                                    ).toFixed(2)
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 400,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 430,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                marginBottom: 2,
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 8,
                                  flex: 1,
                                }}
                              >
                                <div style={{ marginBottom: 10 }}>
                                  {selectedCashRegister
                                    ? "Opened by:" +
                                      selectedCashRegister.openedBy
                                    : null}
                                </div>
                                <div>
                                  {selectedCashRegister
                                    ? "Closed by:" +
                                      selectedCashRegister.closedBy
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: 490,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "90%",
                              fontSize: 10,
                            }}
                          >
                            <div style={{ textAlign: "left", fontSize: 9 }}>
                              STORE CASHIERS TIPS
                            </div>
                            <div style={{ flex: 1 }} />
                            <div style={{ fontSize: 9 }}>AMOUNT DUE</div>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              top: 505,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            <div
                              style={{
                                // borderStyle: "solid",
                                width: "100%",

                                minHeight: 1,
                                // marginTop: 15,
                                backgroundColor: "#C0C0C1",
                              }}
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: 515,
                              left: 20,
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              width: "90%",
                            }}
                          >
                            {storeUsers
                              ? storeUsers.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        width: "100%",
                                        marginTop: 5,
                                        display: "flex",
                                        alignItems: "center",
                                        fontSize: 8,
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: 150,
                                          textAlign: "left",
                                        }}
                                      >
                                        {item
                                          ? item.firstName + " " + item.lastName
                                          : null}
                                      </div>
                                      <div style={{ flex: 1 }} />

                                      <div
                                        style={{
                                          width: 200,
                                          textAlign: "right",
                                        }}
                                      >
                                        {item && selectedCashRegister
                                          ? "R" +
                                            createTipsArrayDisplay({
                                              userId: item._id,
                                            }).toFixed(2)
                                          : null}
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>

                          {/* <img
                  alt="logo here"
                  src={require("../../../../../../images/newLogos/opaque10Blacklogo.png")}
                  style={{
                    position: "absolute",
                    height: 500,
                    zIndex: -1,
                    opacity: 0.4,
                    left: "7%",
                    top: "10%",
                  }}
                /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 0.4,

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div
                style={{
                  height: 60,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#00000010",
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                  justifyContent: "space-evenly",
                }}
              >
                <BaseButtons
                  label={"RECEIPTS"}
                  mini={true}
                  invert={selectedOptionDisplay === "reciepts" ? false : true}
                  size={"30%"}
                  marginTop={-0.1}
                  marginTopFalse={true}
                  onClick={() => setSelectedOptionDisplay("reciepts")}
                  borderRadius={8}
                  height={40}
                />
                <BaseButtons
                  label={"EXCHANGES"}
                  mini={true}
                  size={"30%"}
                  marginTop={-0.1}
                  marginTopFalse={true}
                  invert={selectedOptionDisplay === "exchanges" ? false : true}
                  onClick={() => setSelectedOptionDisplay("exchanges")}
                  borderRadius={8}
                  height={40}
                />
                <BaseButtons
                  label={"REFUNDS"}
                  mini={true}
                  size={"30%"}
                  marginTop={-0.1}
                  marginTopFalse={true}
                  invert={selectedOptionDisplay === "refunds" ? false : true}
                  onClick={() => setSelectedOptionDisplay("refunds")}
                  borderRadius={8}
                  height={40}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  backgroundColor: "#00000010",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                  // marginBottom: 20,
                }}
              >
                {selectedOptionDisplay === "reciepts" ? (
                  selectedCashRegister &&
                  selectedCashRegister.receiptsArray.length >= 1 ? (
                    selectedCashRegister.receiptsArray.map((item, index) => {
                      return (
                        <div
                          onClick={() => setSelectedReceipt(item)}
                          key={index}
                          style={{
                            width: "95%",
                            marginTop: 10,
                            cursor: "pointer",
                            backgroundColor: BlackAndWhite.secondary,
                            borderRadius: 8,
                            WebkitBoxShadow: "0px 0px 7px 2px #00000015",
                            display: "flex",
                            alignItems: "center",
                            marginBottom:
                              index ===
                              selectedCashRegister.receiptsArray.length - 1
                                ? 20
                                : 0,
                            minHeight: 80,
                            maxHeight: 80,
                          }}
                        >
                          <div style={{ marginLeft: 10 }}>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 10,
                                opacity: 0.6,
                                marginBottom: 3,
                              }}
                            >
                              SLIP NO
                            </div>
                            <div style={{ fontSize: 14 }}>{item.slipNo}</div>
                          </div>
                          <div style={{ flex: 1 }} />
                          <div style={{ marginRight: 20 }}>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 10,
                                opacity: 0.6,
                                marginBottom: 3,
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              TYPE:
                            </div>
                            <div style={{ fontSize: 14, textAlign: "left" }}>
                              {item.paymentsStructure[0].item.type}
                            </div>
                          </div>
                          <div style={{ marginRight: 10 }}>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: 10,
                                opacity: 0.6,
                                marginBottom: 3,
                                width: "100%",
                                textAlign: "right",
                              }}
                            >
                              TOTAL:
                            </div>
                            <div style={{ fontSize: 14, textAlign: "right" }}>
                              R {Number(item.total).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>NO RECIPTS FOUND</div>
                  )
                ) : null}
                {selectedOptionDisplay === "refunds" ? (
                  selectedCashRegister &&
                  selectedCashRegister.refundsArray.length >= 1 ? (
                    selectedCashRegister.refundsArray.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "95%",
                            marginTop: 15,
                            marginBottom:
                              index ===
                              selectedCashRegister.receiptsArray.length - 1
                                ? 20
                                : 0,
                            minHeight: 80,
                            maxHeight: 80,
                            backgroundColor: BlackAndWhite.secondary,
                            borderRadius: 8,
                          }}
                        >
                          {item.slipNo}
                        </div>
                      );
                    })
                  ) : (
                    <div>NO REFUNDS FOUND</div>
                  )
                ) : null}
                {selectedOptionDisplay === "exchanges" ? (
                  selectedCashRegister &&
                  selectedCashRegister.exchangesArray.length >= 1 ? (
                    selectedCashRegister.exchangesArray.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            width: "95%",
                            marginTop: 15,
                            marginBottom:
                              index ===
                              selectedCashRegister.receiptsArray.length - 1
                                ? 20
                                : 0,
                            minHeight: 80,
                            maxHeight: 80,
                            backgroundColor: BlackAndWhite.secondary,
                            borderRadius: 8,
                          }}
                        >
                          {item.slipNo}
                        </div>
                      );
                    })
                  ) : (
                    <div>NO EXCHANGES FOUND</div>
                  )
                ) : null}
              </div>
              <div
                style={{
                  height: selectedReceipt ? "100%" : 0,
                  overflow: "hidden",
                  WebkitBoxShadow: "0px 0px 7px 2px #00000030",
                  position: "absolute",
                  // width: "100%",
                  // transition: "height 0.2s",
                  top: 0,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#FFFFFF",
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",

                    // borderBottomRightRadius: 20,
                    // borderBottomLeftRadius: 20,
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 20,
                      left: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <CloseButton
                      label={"X"}
                      onClick={() => setSelectedReceipt(null)}
                    />
                  </div>
                  <div
                    style={{
                      width: "100%",
                      fontSize: 20,
                      textAlign: "center",
                      minHeight: 75,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>RECEIPT HISTORY</div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div style={{ marginTop: 20, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        SLIP NO
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt ? selectedReceipt.slipNo : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        STORE
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt
                          ? selectedReceipt.storeName
                          : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        MEMBER
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt
                          ? selectedReceipt.customerName
                          : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        ADDRESS
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt ? selectedReceipt.Address : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        CONTACT NUMBER
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt
                          ? selectedReceipt.contactNumber
                          : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        CASHIER
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt ? selectedReceipt.name : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        DATE & TIME
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt
                          ? selectedReceipt.dateTime.substring(0, 10) +
                            " at " +
                            selectedReceipt.dateTime.substring(11, 16)
                          : "loading"}
                      </div>
                    </div>
                    <div style={{ marginTop: 15, width: "85%" }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,
                          marginBottom: 3,
                        }}
                      >
                        PAYMENT TYPE
                      </div>
                      <div style={{ fontSize: 14 }}>
                        {selectedReceipt
                          ? selectedReceipt.paymentsStructure[0].item.type
                          : "loading"}
                      </div>
                    </div>
                    <div
                      style={{ marginTop: 25, width: "85%", display: "flex" }}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                          opacity: 0.9,
                          marginBottom: 3,
                        }}
                      >
                        PURCHASED ITEMS
                      </div>
                    </div>
                    {selectedReceipt
                      ? selectedReceipt.productsArray.map((product, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                width: "90%",
                                minHeight: 100,
                                marginTop: index === 0 ? 20 : 30,
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 8,
                                WebkitBoxShadow: "0px 0px 7px 2px #00000030",
                              }}
                            >
                              <div style={{ marginLeft: 10 }}>
                                <img
                                  style={{ height: 60, width: 60 }}
                                  alt="icon"
                                  src={
                                    product ? product.product.imageUrl : null
                                  }
                                />
                              </div>
                              <div
                                style={{
                                  marginLeft: 20,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  maxWidth: 120,
                                  width: 120,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.5,
                                    marginBottom: 3,
                                    width: "100%",
                                  }}
                                >
                                  PRODUCT NAME
                                </div>
                                <div style={{ fontSize: 14, width: "100%" }}>
                                  {product ? product.product.productName : null}
                                </div>
                              </div>
                              <div
                                style={{
                                  marginLeft: 20,
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.5,
                                    marginBottom: 3,
                                  }}
                                >
                                  QTY
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  {product ? product.qtyCard.qty : null}
                                </div>
                              </div>
                              <div style={{ flex: 1 }} />
                              <div
                                style={{
                                  marginRight: 20,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.5,
                                    marginBottom: 3,
                                  }}
                                >
                                  TOTAL
                                </div>
                                <div style={{ fontSize: 14 }}>
                                  R{" "}
                                  {product
                                    ? Number(
                                        product.product.retailPriceInc *
                                          product.qtyCard.qty
                                      ).toFixed(2)
                                    : null}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "loading"}

                    <div
                      style={{ marginTop: 30, width: "85%", display: "flex" }}
                    >
                      <div style={{ flex: 1 }} />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            opacity: 0.5,
                            // marginBottom: 3,
                            textAlign: "right",
                            marginRight: 10,
                          }}
                        >
                          SUB-TOTAL :
                        </div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          {selectedReceipt
                            ? "R " + Number(selectedReceipt.subTotal).toFixed(2)
                            : "loading"}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 25,
                        width: "85%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: 1 }} />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            opacity: 0.5,
                            // marginBottom: 3,
                            textAlign: "right",
                            marginRight: 10,
                          }}
                        >
                          VAT :
                        </div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          {selectedReceipt
                            ? "R " + Number(selectedReceipt.tax).toFixed(2)
                            : "loading"}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: 25,
                        width: "85%",
                        display: "flex",
                        marginBottom: 20,
                      }}
                    >
                      <div style={{ flex: 1 }} />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            opacity: 0.5,
                            // marginBottom: 3,
                            textAlign: "right",
                            marginRight: 10,
                          }}
                        >
                          TOTAL :
                        </div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          {selectedReceipt
                            ? "R " + Number(selectedReceipt.total).toFixed(2)
                            : "loading"}
                        </div>
                      </div>
                    </div>
                    {/* <div style={{ marginTop: 25, width: "85%" }}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    opacity: 0.9,
                    marginBottom: 3,
                  }}
                >
                  PAYMENTS
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default Reporting;
