import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const InventoryManageCard = ({
  productName,
  NameAssemble,
  selectedProduct,
  product,
  sku,
  supplier,
  image,
  info,
  stockValue,
  ReturnStockAmount,
  stockTakeDate,
  onVerifyClick,
}) => {
  const [areSure, setAreSure] = useState(false);

  const [backgroundColorSelections, setBackgroundColorSelections] = useState(
    BlackAndWhite.primary
  );

  let dateCheck = new Date();
  let minus1days = new Date();
  let minus2days = new Date();
  let minus3days = new Date();
  let minus4days = new Date();
  let minus5days = new Date();
  let minus6days = new Date();
  let minus7days = new Date();
  let minus8days = new Date();

  const buttonColorCheck = () => {
    minus1days.setDate(dateCheck.getDate() - 1);
    minus2days.setDate(dateCheck.getDate() - 2);
    minus3days.setDate(dateCheck.getDate() - 3);
    minus4days.setDate(dateCheck.getDate() - 4);
    minus5days.setDate(dateCheck.getDate() - 5);
    minus6days.setDate(dateCheck.getDate() - 6);
    minus7days.setDate(dateCheck.getDate() - 7);
    minus8days.setDate(dateCheck.getDate() - 8);

    if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(dateCheck.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus1days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus2days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus3days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus4days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#48A14D");
    } else if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus5days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus6days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#FFBF46");
    } else if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus7days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus8days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#DB5461");
    } else {
      setBackgroundColorSelections("#DB5461");
    }
  };
  useEffect(() => {
    if (stockTakeDate) {
      buttonColorCheck();
    }
  }, [stockTakeDate]);
  // const [info, setInfo] = useState(null);
  // const [stockValue, setStockValue] = useState(null);
  // const GetWareHouseStockInfo = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           productId: product._id,
  //         }),
  //       }
  //     );

  //     const responseData = await response.json();

  //     if (responseData.success) {
  //       setInfo(responseData.stockAmount);
  //       setStockValue(responseData.stockAmount.currentStock);
  //       // setMessage(responseData.success);
  //       // setToastPop(true);
  //     }
  //   } catch (err) {
  //     console.log("error", err);
  //   }
  // };

  // useEffect(() => {
  //   GetWareHouseStockInfo();
  // }, []);
  return (
    <div
      style={{
        height: 70,
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: "#00000030",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 0.05,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          overflow: "hidden",
        }}
      >
        <img alt="product" src={image} style={{ height: 50 }} />
      </div>
      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          {productName}
        </div>
      </div>

      <div
        style={{
          flex: 0.2,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 35, width: "100%", textAlign: "left" }}>
          {sku}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
          {supplier}
        </div>
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          <TextInputField
            invert={true}
            mini={true}
            height={10}
            marginTop={-5}
            size={170}
            labelTitle={"Total"}
            type={"Number"}
            readOnly={stockValue ? false : true}
            value={info ? info.currentStock : "loading"}
            // onChange={(e) => setStockValue(e.target.value)}
          />
        </div>
      </div>
      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 12.5 }}>
          {stockTakeDate ? stockTakeDate.substring(0, 10) : null}
        </div>
      </div>

      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          fontWeight={"bold"}
          label={"VERIFY"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          height={35}
          size={130}
          borderWidth={0}
          backgroundColor={backgroundColorSelections}
          onClick={onVerifyClick}
        />
      </div>
    </div>
  );
};

export default InventoryManageCard;
