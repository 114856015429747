import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const DropDownOwners = ({
  data,
  display,
  setSelected,
  setOwnerPopUp,
  setOwnerSlectedObject,
}) => {
  return (
    <div
      style={{
        width: "100%",
        overflow: "hidden",
        height: display ? null : 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: BlackAndWhite.secondary,
        top: 0,
        position: "absolute",
        zIndex: 2,
        paddingTop: display ? 10 : 0,
        paddingBottom: display ? 10 : 0,
      }}
    >
      {data
        ? data.map((owner, index) => {
            return (
              <div
                onClick={() => {
                  setOwnerSlectedObject(owner);
                  setOwnerPopUp(false);
                }}
                style={{
                  minHeight: 40,
                  width: "90%",
                  borderStyle: "solid",
                  borderRadius: 4,
                  marginTop: 10,
                  borderWidth: 0.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                key={index}
              >
                {owner.firstName + " " + owner.lastName}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default DropDownOwners;
