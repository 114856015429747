import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons } from "../../../../components/atoms";

const ProfileMenu = ({
  display,
  username,
  onCancelClick,
  idNum,
  store,
  province,
  onLogOutClick,
}) => {
  const [outOfView, setOutOfView] = useState(false);
  return (
    <div
      onClick={outOfView ? onCancelClick : null}
      style={{
        // backgroundColor: "#00000050",
        height: display ? "100%" : 0,
        width: "100%",
        zIndex: 999999999999999,
        position: "fixed",
      }}
    >
      <div
        onMouseOver={() => setOutOfView(false)}
        onMouseOut={() => setOutOfView(true)}
        style={{
          height: display ? 300 : 0,
          backgroundColor: BlackAndWhite.secondary,
          overflow: "hidden",
          position: "fixed",
          top: 70,
          right: 10,
          borderRadius: 8,
          width: 250,
          WebkitBoxShadow: display
            ? "0px 0px 10px 1px #00000055"
            : "0px 0px 0px 0px #00000055",
          transition: "all 0.2s",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 3000000,
          // borderStyle: "solid",
          // borderWidth: display ? 0.5 : 0,
          borderColor: BlackAndWhite.primary,
        }}
      >
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginTop: 10,
              width: "85%",
              textAlign: "left",
              fontSize: 12,
              opacity: 0.6,
              fontWeight: "bold",
            }}
          >
            USERNAME
          </div>
          <div
            style={{
              marginTop: 5,
              width: "85%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {username}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "85%",
              textAlign: "left",
              fontSize: 12,
              opacity: 0.6,
              fontWeight: "bold",
            }}
          >
            ID-NUMBER
          </div>
          <div
            style={{
              marginTop: 5,
              width: "85%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {idNum}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "85%",
              textAlign: "left",
              fontSize: 12,
              opacity: 0.6,
              fontWeight: "bold",
            }}
          >
            STORE
          </div>
          <div
            style={{
              marginTop: 5,
              width: "85%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {store}
          </div>
          <div
            style={{
              marginTop: 10,
              width: "85%",
              textAlign: "left",
              fontSize: 12,
              opacity: 0.6,
              fontWeight: "bold",
            }}
          >
            PROVINCE
          </div>
          <div
            style={{
              marginTop: 5,
              width: "85%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
            }}
          >
            {province}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <BaseButtons
            label={"CLOSE"}
            height={35}
            size={110}
            onClick={onCancelClick}
            mini={true}
            borderRadius={4}
          />
          <BaseButtons
            label={"LOG-OUT"}
            height={35}
            size={110}
            onClick={onLogOutClick}
            mini={true}
            borderRadius={4}
          />
        </div>

        <div style={{ height: 10 }} />
      </div>
    </div>
  );
};

export default ProfileMenu;
