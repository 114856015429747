import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  CardItem,
  CategorySelector,
  FilterDropDown,
  ListItem,
  ProductHeaderKnowledge,
} from "./components";
import { HeadersPatients } from "../patient-display/components";

const KnowledgeBasePanel = ({
  setToastPop,
  setLoading,
  setMessage,
  setSelectedProductDisplay,
  refresh,
  setRefresh,
}) => {
  const [search, setSearch] = useState("");
  const [selectedView, setSelectedView] = useState("list");
  const [allProductsArray, setAllProductsArray] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [filterOption, setFilterOption] = useState("CATEGORY");
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        (product.thcType === "None" ? "" : product.thcType) +
        (product.thcType === "None" ? "" : " | ") +
        (product.flavour === "None" ? "" : product.flavour) +
        (product.flavour === "None" ? "" : " | ") +
        product.thcContent;
      return name;
    }
  };
  useEffect(() => {
    setLoading(true);
    fetch(
      "https://merry-jane-api.onrender.com/knowledgeBase/getAllProductsKnowledge"
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setAllProductsArray(resData.product);
        setLoading(false);
      });
  }, [refresh]);
  return (
    <div
      style={{
        height: "95%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {/* header search tab and display switch */}
      <div
        style={{
          minHeight: 60,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              // width: 300,

              borderWidth: 0.5,
            }}
          >
            <TextInputFieldSearch
              placeholder={"Type here to search"}
              height={30}
              borderRadius={4}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => setSearch("")}
                style={{ fontSize: 12, fontWeight: "bold" }}
              >
                CLEAR
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{ marginRight: 10, marginBottom: 0 }}>
          <FilterDropDown
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedSubCategory={selectedSubCategory}
            setSelectedSubCategory={setSelectedSubCategory}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
          />
        </div>
        {/* actions displays */}
        <div
          style={{
            backgroundColor: BlackAndWhite.primary,
            display: "flex",
            paddingTop: 5,
            paddingBottom: 5,
            borderRadius: 8,
            marginRight: 10,
            marginBottom: 5,
          }}
        >
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <BaseButtons
              mini={true}
              borderRadius={8}
              label={"CARD"}
              height={30}
              marginTopFalse={true}
              size={90}
              onClick={() => setSelectedView("card")}
              invert={selectedView === "card" ? false : true}
            />
          </div>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <BaseButtons
              mini={true}
              borderRadius={8}
              label={"LIST"}
              height={30}
              marginTopFalse={true}
              size={90}
              onClick={() => setSelectedView("list")}
              invert={selectedView === "list" ? false : true}
            />
          </div>
        </div>
      </div>
      {selectedView !== "card" ? <ProductHeaderKnowledge /> : null}
      {/* <CategorySelector /> */}
      <div
        style={{
          flex: 1,

          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          display: "flex",
          flexDirection: selectedView === "card" ? null : "column",
          justifyContent: selectedView === "card" ? null : "flex-start",
          alignItems: selectedView === "card" ? null : "center",
          flexWrap: selectedView === "list" ? null : "wrap",
        }}
      >
        {selectedView === "list"
          ? allProductsArray
            ? allProductsArray.map((product, index) => {
                return selectedCategory ? (
                  selectedCategory.categoryName === product.productType ? (
                    selectedSubCategory ? (
                      selectedSubCategory.subCategoryName ===
                      product.productSubType ? (
                        NameAssemble({ product })
                          .toLocaleLowerCase()
                          .match(search.toLocaleLowerCase()) ? (
                          <div style={{ width: "100%" }} key={index}>
                            <ListItem
                              productName={
                                product ? NameAssemble({ product }) : "loading"
                              }
                              image={product.imageUrl}
                              category={product.productType}
                              subcategory={product.productSubType}
                              sku={product.sku}
                              productfeature={product.strain}
                              productPref={product.flavour}
                              saleSize={product.size}
                              unitMeasure={product.quantityDenomiation}
                              retail={"R " + product.retailPriceInc}
                              onClick={() => setSelectedProductDisplay(product)}
                            />
                          </div>
                        ) : null
                      ) : null
                    ) : NameAssemble({ product })
                        .toLocaleLowerCase()
                        .match(search) ? (
                      <div style={{ width: "100%" }} key={index}>
                        <ListItem
                          productName={
                            product ? NameAssemble({ product }) : "loading"
                          }
                          image={product.imageUrl}
                          category={product.productType}
                          subcategory={product.productSubType}
                          sku={product.sku}
                          productfeature={product.strain}
                          productPref={product.flavour}
                          saleSize={product.size}
                          unitMeasure={product.quantityDenomiation}
                          retail={"R " + product.retailPriceInc}
                          onClick={() => setSelectedProductDisplay(product)}
                        />
                      </div>
                    ) : null
                  ) : null
                ) : NameAssemble({ product })
                    .toLocaleLowerCase()
                    .match(search) ? (
                  <div style={{ width: "100%" }} key={index}>
                    <ListItem
                      productName={
                        product ? NameAssemble({ product }) : "loading"
                      }
                      image={product.imageUrl}
                      category={product.productType}
                      subcategory={product.productSubType}
                      sku={product.sku}
                      productfeature={product.strain}
                      productPref={product.flavour}
                      saleSize={product.size}
                      unitMeasure={product.quantityDenomiation}
                      retail={"R " + product.retailPriceInc}
                      onClick={() => setSelectedProductDisplay(product)}
                    />
                  </div>
                ) : null;
              })
            : null
          : null}
        {selectedView === "card"
          ? allProductsArray
            ? allProductsArray.map((product, index) => {
                return selectedCategory ? (
                  selectedCategory.categoryName === product.productType ? (
                    selectedSubCategory ? (
                      selectedSubCategory.subCategoryName ===
                      product.productSubType ? (
                        NameAssemble({ product })
                          .toLocaleLowerCase()
                          .match(search.toLocaleLowerCase()) ? (
                          <div key={index}>
                            <CardItem
                              productName={NameAssemble({ product })}
                              image={product.imageUrl}
                              category={"Category FSC"}
                              subcategory={"FSC example"}
                              sku={"FLW-0015IN"}
                              productfeature={"Indoor"}
                              productPref={"50% Indica | 50% sativa"}
                              saleSize={"1"}
                              unitMeasure={"grams (g)"}
                              retail={"R " + product.retailPriceInc}
                              onClick={() => setSelectedProductDisplay(product)}
                            />
                          </div>
                        ) : null
                      ) : null
                    ) : NameAssemble({ product })
                        .toLocaleLowerCase()
                        .match(search) ? (
                      <div key={index}>
                        <CardItem
                          productName={NameAssemble({ product })}
                          image={product.imageUrl}
                          category={"Category FSC"}
                          subcategory={"FSC example"}
                          sku={"FLW-0015IN"}
                          productfeature={"Indoor"}
                          productPref={"50% Indica | 50% sativa"}
                          saleSize={"1"}
                          unitMeasure={"grams (g)"}
                          retail={"R " + product.retailPriceInc}
                          onClick={() => setSelectedProductDisplay(product)}
                        />
                      </div>
                    ) : null
                  ) : null
                ) : NameAssemble({ product })
                    .toLocaleLowerCase()
                    .match(search) ? (
                  <div key={index}>
                    <CardItem
                      productName={NameAssemble({ product })}
                      image={product.imageUrl}
                      category={"Category FSC"}
                      subcategory={"FSC example"}
                      sku={"FLW-0015IN"}
                      productfeature={"Indoor"}
                      productPref={"50% Indica | 50% sativa"}
                      saleSize={"1"}
                      unitMeasure={"grams (g)"}
                      retail={"R " + product.retailPriceInc}
                      onClick={() => setSelectedProductDisplay(product)}
                    />
                  </div>
                ) : null;
              })
            : null
          : null}
      </div>
    </div>
  );
};
export default KnowledgeBasePanel;
