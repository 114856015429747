import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import StatCardType from "./stat-card/StatCardType";
import { BaseButtons } from "../../../../components/atoms";
import { SupportModal, SupportTicketItem } from "./components";

const DashBoardPos = ({
  user,
  refresh,
  setLoading,
  setSearch,
  search,
  OnUserSearch,
  customers,
  setCustomers,
  setToastPop,
  setMessage,
  setRefresh,
}) => {
  const [storeStatsData, setStoreStatsData] = useState(null);
  const [nameHasSplit, setNameHasSplit] = useState(null);

  const [supportModalPopUp, setSupportModalPopUp] = useState(false);

  const [infoPopUp, setInfoPopUp] = useState(false);

  const [supportTicketsArray, setSupportTickectsArray] = useState(null);

  const SplitName = () => {
    let name = user.adminUserData.storeName;
    const nameSplit = name.split(" ");

    if (nameSplit.length >= 3) {
      setNameHasSplit(nameSplit[1] + " " + nameSplit[2]);
      return nameSplit[1] + " " + nameSplit[2];
    } else {
      setNameHasSplit(nameSplit[1]);
      return nameSplit[1];
    }
  };

  const GetStoreStats = async () => {
    setLoading(true);
    setStoreStatsData(null);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/storeStats/getStoreStats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: SplitName(),
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setStoreStatsData(respnseData);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const GetSupportTickets = async () => {
    setLoading(true);
    setStoreStatsData(null);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/getStoreSupportTicket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: user.adminUserData.userId,
          }),
        }
      );

      const respnseData = await response.json();

      if (!respnseData.error) {
        setSupportTickectsArray(respnseData.data);
        setLoading(false);
        // setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      GetStoreStats();
      GetSupportTickets();
    }
  }, [refresh]);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* // support button help description */}
      <div
        onClick={() => setInfoPopUp(infoPopUp ? false : true)}
        style={{
          cursor: "pointer",
          height: infoPopUp ? 150 : 50,
          width: infoPopUp ? "100%" : 50,
          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: infoPopUp ? "relative" : "absolute",
          right: infoPopUp ? null : 50,
          top: infoPopUp ? null : -10,
          transition: "all 0.3s",
          zIndex: 200,
          // overflow: "hidden",
        }}
      >
        <div
          style={{
            // maxWidth: 800,
            width: "90%",
            backgroundColor: infoPopUp
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
            WebkitBoxShadow: "0px 0px 15px 2px #00000050 ",
            height: "90%",
            borderRadius: 8,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "90%",
              opacity: infoPopUp ? 1 : 0,
              transition: "all 0.3s",
              height: infoPopUp ? "100%" : 0,
              textAlign: "left",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              Welcome to the Merry-Jane Point of Sale (POS) System, designed to
              revolutionize the cannabis industry by offering a comprehensive
              and seamless digital framework. Our POS system is crafted to
              enhance interactions and provide a holistic view of your business
              operations. As we advance, we will continuously introduce new
              functions to meet the evolving needs of this dynamic industry.
            </div>
          </div>
          <div
            style={{
              opacity: infoPopUp ? 0 : 1,
              transition: "all 0.5s",
              height: infoPopUp ? 0 : null,
              width: infoPopUp ? 0 : null,
              overflow: "hidden",
              fontSize: 30,
              fontWeight: "bold",
              color: BlackAndWhite.secondary,
            }}
          >
            ?
          </div>
        </div>
      </div>

      <StatCardType
        // productStats={productStats}
        // onClick={patientStatsAvalible ? () => setStatType("patient") : null}
        title={"NATIONAL REGISTRATIONS"}
        type={"registration"}
        icon={require("../../../../images/material-icons/profileBlack.png")}
        iconHeight={35}
        iconWidth={35}
        item1Title={"TOTAL"}
        item1Value={storeStatsData ? storeStatsData.allUsers : "loading"}
        item2Title={"YESTERDAY"}
        item2Value={
          storeStatsData ? storeStatsData.allUsersYesterday : "loading"
        }
        item3Title={"TODAY"}
        item3Value={storeStatsData ? storeStatsData.allUsersToday : "loading"}
        item4Title={"LAST 30 DAYS"}
        item4Value={storeStatsData ? storeStatsData.allUsers30days : "loading"}
      />
      <StatCardType
        // productStats={productStats}
        // onClick={patientStatsAvalible ? () => setStatType("patient") : null}
        title={"VALIDATED STORE USERS"}
        type={"registration"}
        icon={require("../../../../images/material-icons/profileBlack.png")}
        iconHeight={35}
        iconWidth={35}
        item1Title={"TOTAL"}
        item1Value={storeStatsData ? storeStatsData.activeUsers : "loading"}
        item2Title={"YESTERDAY"}
        item2Value={
          storeStatsData ? storeStatsData.activesUsersYesterday : "loading"
        }
        item3Title={"TODAY"}
        item3Value={
          storeStatsData ? storeStatsData.activesUsersToday : "loading"
        }
        item4Title={"LAST 7 DAYS"}
        item4Value={
          storeStatsData ? storeStatsData.activesUsersLast7days : "loading"
        }
      />

      {/* <div
        style={{
          width: "80%",
          height: 200,
          borderRadius: 8,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 8,
          WebkitBoxShadow: "0px 0px 15px 2px #00000050 ",
          marginBottom: 30,
          padding: 20,
          display: "flex",
          flexDirection: "row",
          marginTop: 60,
          position: "relative",
        }}
      >
        STORE REGISTRATIONS AND VALIDATIONS
      </div> */}
    </div>
  );
};

export default DashBoardPos;
