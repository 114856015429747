import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  SectionTitle,
  TextAreaInput,
  TextInputField,
} from "../../components/atoms";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import AdminCustomerTab from "./components/AdminCustomerTab";
import QuestionButton from "../customer-portal/components/medical-history/components/button/QuestionButton";
import Users from "./components/Users";
import {
  LoadingPopUp,
  MenuDropDown,
  ToastNotification,
} from "../../components";
import DropDownButton from "../../components/drop-down-button/DropDownButton";
import Facilities from "./components/Facilities";
import Menus from "./components/Menus";
import { CSVLink } from "react-csv";
import DashBoard from "./components/DashBoard";
import MultiSelectorDate from "./components/multi-selector-date/MultiSelectorDate";
import MainScreenHolder from "./components/product-uploader/MainScreenHolder";
import ExcelExport from "./components/ExcelExport";
import { ProfileMenu } from "../canna-pos/components";
import Reporting from "./components/reporting/Reporting";
import StaffDisplay from "./components/staff-display/StaffDisplay";
import DashBoardPos from "./components/dashboard-pos/DashBoardPos";

// import { useNavigate } from "react-router-dom";

const StoreOwnerPortal = () => {
  const { width, height } = useWindowDimensions();
  const [shrink, setShrink] = useState(false);

  const [onMenu, setOnMenu] = useState(false);
  const [patientStats, setPatientsStats] = useState(null);
  const [prescriptionStats, setPrescriptionStats] = useState(null);

  const [userDisplay, setUserdisplay] = useState(null);

  const [subSelection, setSubSelection] = useState("sales");

  const [storeInfo, setStoreInfo] = useState(null);

  const [employeesArray, setEmployeesArray] = useState(null);
  const [reportsList, setReportsList] = useState(null);

  const [tab, setTab] = useState("reporting");

  // const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState("loading");

  const [toastPop, setToastPop] = useState(false);
  // const [error, setError] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const GetStoreInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/fetchStoreInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: admin.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreInfo(responseData.store);
      }
    } catch (err) {
      setMessage(
        "Cant connect to database and get store info, check your network connection ... if problem persist's contact support"
      );
      setToastPop(true);
    }
  };

  const GetStoreReportsAndBasePullDown = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStoresOwnerReports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: admin.adminUserData.userId,
            storeId: storeInfo.storeId,
            storeName: storeInfo.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setEmployeesArray(responseData.storeEmployees);
        setReportsList(responseData.reportslist);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setMessage(
        "Cant connect to database and get store info, check your network connection ... if problem persist's contact support"
      );
      setLoading(false);
      setToastPop(true);
    }
  };

  useEffect(() => {
    if (
      !admin.isloggedIn ||
      admin.adminUserData.type === "Customer" ||
      admin.adminUserData.type === "Doctor" ||
      admin.adminUserData.type === "Manager" ||
      admin.adminUserData.type === "Support" ||
      admin.adminUserData.type === "Investor"
    ) {
      navigate("/adminPortal");
    }
  }, []);
  useEffect(() => {
    GetStoreInfo();
  }, []);
  useEffect(() => {
    if (storeInfo) {
      GetStoreReportsAndBasePullDown();
    }
  }, [storeInfo]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);

  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "700",
          color: BlackAndWhite.secondary,
          position: "fixed",
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          top: 0,
          height: 60,
          display: "flex",
          alignItems: "center",
          zIndex: 2000,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
        }}
      >
        <div
          onClick={() => setShrink(shrink ? false : true)}
          style={{
            marginLeft: shrink ? 15 : 5,
            backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
            height: shrink ? 95 : 135,
            width: shrink ? 95 : 202.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: shrink ? 200 : 20,
            position: "absolute",
            top: shrink ? 10 : 5,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            transition: "all 0.3s",
            // borderStyle: "solid",
            // borderColor: BlackAndWhite.primary,
            // borderWidth: 5,
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              // marginLeft: shrink ? 5 : 5,
              backgroundColor: shrink
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 8,
              // position: "absolute",
              // top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <img
              alt="load"
              src={
                shrink
                  ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                  : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
              }
              style={{
                height: shrink ? 65 : 120,
                transition: "all 0.3s",
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: shrink ? 125 : 235,
              fontWeight: "bolder",
              transition: "all 0.3s",
              fontSize: 15,
            }}
          >
            {tab ? tab.toLocaleUpperCase() : "loading"}
          </div>
        </div>
        <div
          style={{
            fontSize: 14,
            marginRight: 20,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 10 }}>Store : </div>
          <div style={{ padding: 10, borderStyle: "solid", borderRadius: 6 }}>
            {storeInfo ? storeInfo.storeName : "loading"}
          </div>
        </div>
        <div
          style={{
            marginRight: 20,
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "normal",
          }}
        >
          <div style={{ marginRight: 4 }}>
            {admin.adminUserData ? admin.adminUserData.firstName : "loading"}
          </div>
          <div style={{ marginRight: 12 }}>
            {admin.adminUserData ? admin.adminUserData.lastName : "loading"}
          </div>
          {/* //menu button */}
          <div
            onClick={() => setOnMenu(onMenu ? false : true)}
            style={{
              height: 25,
              width: 25,
              backgroundColor: onMenu
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              borderRadius: 100,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              transition: "0.3s",
            }}
          >
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // width: shrink ? 100 : 245,
          width: 245,
          backgroundColor: "#FFFFFF",
          height: "100%",
          marginTop: 10,
          alignItems: "center",
          transition: "all 0.3s",
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          zIndex: 1000,
          marginLeft: 0,
        }}
      >
        <div style={{ height: shrink ? 110 : 150, transition: "all 0.3s" }} />
        {/* {tab === "dashboard" ? null : (
          <DropDownButton
            title={"BACK"}
            width={"95%"}
            height={40}
            onClick={() => {
              setTab("reporting");
              setUserdisplay(false);
              setSubSelection("none");
            }}
          />
        )} */}
        {/* {tab === "dashboard" ? (
          <>
            <QuestionButton
              borderOn={tab === "dashboard" ? true : false}
              title={"DASHBOARD"}
              height={60}
              onClick={() => setTab("dashboard")}
              backgroundColor={
                tab === "dashboard"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "dashboard"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"90%"}
              icon={
                tab === "dashboard"
                  ? require("../../images/material-icons/dashboardWhite.png")
                  : require("../../images/material-icons/dashboardBlack.png")
              }
              selected={tab === "dashboard" ? true : false}
              questionAmount={true}
              borderRadius={10}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null} */}

        <>
          <QuestionButton
            borderRadius={10}
            borderOn={tab === "dashboard" ? true : false}
            title={"DASHBOARD"}
            height={60}
            onClick={() => setTab("dashboard")}
            backgroundColor={
              tab === "dashboard"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "dashboard"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "dashboard"
                ? require("../../images/material-icons/dashboardWhite.png")
                : require("../../images/material-icons/dashboardBlack.png")
            }
            selected={tab === "dashboard" ? true : false}
            questionAmount={true}
          />
          {/* <MenuDropDown
              display={tab === "user" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["ALL USERS", "ADD USERS", "STATS"]}
            /> */}
          <div style={{ height: 5 }} />
        </>

        <>
          <QuestionButton
            borderRadius={10}
            borderOn={tab === "reporting" ? true : false}
            title={"REVENUE"}
            height={60}
            onClick={() => {
              setTab("reporting");
              setSubSelection("sales");
            }}
            backgroundColor={
              tab === "reporting"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "reporting"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "reporting"
                ? require("../../images/material-icons/setupAndMangeWhite.png")
                : require("../../images/material-icons/setupAndMangeBlack.png")
            }
            selected={tab === "reporting" ? true : false}
            questionAmount={true}
          />
          {/* <MenuDropDown
              display={tab === "reporting" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["MAIN PAGE", "MERRY FACES", "IMAGES"]}
            /> */}
          <div style={{ height: 5 }} />
        </>
        <>
          <QuestionButton
            disable={true}
            borderRadius={10}
            borderOn={tab === "inventory" ? true : false}
            title={"INVENTORY"}
            height={60}
            onClick={() => setTab("inventory")}
            backgroundColor={
              tab === "inventory"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "inventory"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "inventory"
                ? require("../../images/material-icons/inventoryWhite.png")
                : require("../../images/material-icons/inventoryBlack.png")
            }
            selected={tab === "inventory" ? true : false}
            questionAmount={true}
          />
          {/* <MenuDropDown
              display={tab === "user" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["ALL USERS", "ADD USERS", "STATS"]}
            /> */}
          <div style={{ height: 5 }} />
        </>
        <>
          <QuestionButton
            disable={true}
            borderRadius={10}
            borderOn={tab === "productInfo" ? true : false}
            title={"PRODUCT INFO"}
            height={60}
            onClick={() => setTab("productInfo")}
            backgroundColor={
              tab === "productInfo"
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "productInfo"
                ? BlackAndWhite.secondary
                : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "productInfo"
                ? require("../../images/material-icons/productWhite.png")
                : require("../../images/material-icons/productBlack.png")
            }
            selected={tab === "productInfo" ? true : false}
            questionAmount={true}
          />
          {/* <MenuDropDown
              display={tab === "user" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["ALL USERS", "ADD USERS", "STATS"]}
            /> */}
          <div style={{ height: 5 }} />
        </>
        <>
          <QuestionButton
            borderRadius={10}
            borderOn={tab === "user" ? true : false}
            title={"USERS"}
            height={60}
            onClick={
              tab === "user" ? () => setTab("dashboard") : () => setTab("user")
            }
            backgroundColor={
              tab === "user" ? BlackAndWhite.primary : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "user" ? BlackAndWhite.secondary : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "user"
                ? require("../../images/material-icons/usersWhite.png")
                : require("../../images/material-icons/userBlack.png")
            }
            selected={tab === "user" ? true : false}
            questionAmount={true}
          />
          {/* <MenuDropDown
              display={tab === "user" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["ALL USERS", "ADD USERS", "STATS"]}
            /> */}
          <div style={{ height: 5 }} />
        </>

        {/* {tab === "products" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // inProgress={true}
              borderRadius={10}
              borderOn={tab === "products" ? true : false}
              title={"PRODUCTS"}
              height={60}
              onClick={() => {
                setTab("products");
                setSubSelection("PRODUCT KNOWLEDGE");
              }}
              backgroundColor={
                tab === "products"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "products"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "products"
                  ? require("../../images/material-icons/productWhite.png")
                  : require("../../images/material-icons/productBlack.png")
              }
              selected={tab === "products" ? true : false}
              questionAmount={true}
            />
            {/* <MenuDropDown
              display={tab === "products" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["PRODUCT KNOWLEDGE"]}
            /> */}
        {/* <div style={{ height: 5 }} />
          </>
        ) : null}  */}

        <div style={{ height: 5 }} />
      </div>

      {/* screen section not menu buttons */}
      <div
        style={{
          height: "95%",
          width: "100%",
          overflow: "scroll",
          marginTop: 85,
          position: "relative",
          backgroundColor: BlackAndWhite.secondary,
          // marginTop: 50,
        }}
      >
        {tab === "reporting" ? (
          <Reporting
            auth={admin ? admin.adminUserData : "loading"}
            selectedStore={storeInfo ? storeInfo : null}
            subSelection={subSelection}
            generatedReports={reportsList}
          />
        ) : null}
        {tab === "products" ? <div>Products and inventory</div> : null}

        {tab === "user" ? (
          <StaffDisplay employeesArray={employeesArray} />
        ) : null}

        {tab === "dashboard" ? (
          <DashBoardPos
            user={admin}
            refresh={refresh}
            setLoading={setLoading}
          />
        ) : null}
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            // height: "100%",
            zIndex: 20000000,
            position: "absolute",
            top: 0,
          }}
        >
          <LoadingPopUp display={loading} />
        </div>
      ) : null}
      <ProfileMenu
        display={onMenu}
        username={
          admin.adminUserData ? admin.adminUserData.userName : "loading"
        }
        idNum={admin.adminUserData ? admin.adminUserData.idDoc : "loading"}
        store={admin.adminUserData ? admin.adminUserData.storeName : "loading"}
        province={
          admin.adminUserData ? admin.adminUserData.province : "loading"
        }
        onCancelClick={() => setOnMenu(false)}
      />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default StoreOwnerPortal;
