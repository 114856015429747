import React, { useState, useEffect } from "react";

import {
  StoreTransferListItem,
  TransferFilter,
  Transferheader,
  TransferItem,
  TransferManageView,
} from "./components";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../../../components/atoms";

const Transfers = ({ auth, setLoading, setMessage, setToastPop, loading }) => {
  const [screenDisplay, setScreenDisplay] = useState("all");
  const [search, setSearch] = useState("");
  const [transferDocsArray, setTransferDocsArray] = useState([]);
  const [storesArray, setStoresArray] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [selectedTransfer, setSelectedTransfer] = useState(null);

  const GetTransferDocsAll = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getTransferDocs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setTransferDocsArray(responseData.tansferDocs);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const getStores = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        // setLoading(false);
        setStoresArray(respnseData.stores);
      }
    } catch (err) {
      console.log("test", err);
    }
  };

  const SelectTranfersForView = (transfer) => {
    setSelectedTransfer(transfer);
  };
  useEffect(() => {
    getStores();
    GetTransferDocsAll();
  }, [refresh]);
  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          minHeight: 60,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            // width: 300,

            borderWidth: 0.5,
          }}
        >
          <TextInputFieldSearch
            placeholder={"TYPE HERE TO SEARCH STORE"}
            height={25}
            borderRadius={4}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div
            style={{
              height: 30,
              width: 70,
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 4,
              display: "flex",
              color: BlackAndWhite.secondary,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <div
              onClick={() => setSearch("")}
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              CLEAR
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }} />
        <div style={{}}>
          <TransferFilter filterName={"FILTER"} setScreen={setScreenDisplay} />
        </div>

        <div style={{ minWidth: 10 }} />
      </div>
      <Transferheader />
      <div
        style={{
          // maxHeight: 700,
          minHeight: 500,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          justifyContent: "flex-start",
          // flex: 1,
          height: "90%",
        }}
      >
        <div
          style={{
            width: "100%",
            // minHeight: 60,

            display: "flex",
            alignItems: "center",
            marginBottom: 5,
            backgroundColor: "#D9D6DC",
          }}
        >
          <StoreTransferListItem
            store={{ storeName: "Warehouse" }}
            index={"warehouse"}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            transferDocsArray={transferDocsArray}
            screenDisplay={screenDisplay}
            search={search}
            SelectTranfersForView={setSelectedTransfer}
          />
        </div>

        {storesArray
          ? storesArray.map((store, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    // minHeight: 60,

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: storesArray
                      ? index === storesArray.length - 1
                        ? 50
                        : 5
                      : 5,
                    backgroundColor: "#D9D6DC",
                  }}
                >
                  <StoreTransferListItem
                    store={store}
                    index={index}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    transferDocsArray={transferDocsArray}
                    screenDisplay={screenDisplay}
                    SelectTranfersForView={SelectTranfersForView}
                    storesArray={storesArray}
                  />
                </div>
              );
            })
          : null}
      </div>
      <TransferManageView
        display={selectedTransfer}
        setSelectedTransfer={setSelectedTransfer}
        setRefresh={setRefresh}
        refresh={refresh}
        auth={auth}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        loading={loading}
      />
    </div>
  );
};
export default Transfers;
