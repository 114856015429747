import React, { useState, useEffect } from "react";
import FormFields from "../../../form/FormFields";
import TabMobileButton from "../tab-mobile-button/TabMobileButton";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { TermsDisplay } from "./components";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";
import { ModalLayout } from "../../../../../store-owner-portal/components/reporting/components";

const Profile = ({
  selectedDisplay,
  profileProgress,
  customerName,
  patientNumber,
  firstName,
  lastName,
  idDoc,
  dateOfBirth,
  cellNumber,
  email,
  refreshHandler,
  loading,
  setValidateEdit,
  auth,
  prescriptionData,
  accountType,
  setLoading,
  setToastPop,
  setMessage,
  agreements,
  setAgreements,
  viewTerms,
  setViewTerms,
}) => {
  const [signedAt, setSignedAt] = useState("");

  const [recieptsPopUp, setRecieptsPopUp] = useState(false);

  const [receiptsArray, setReceiptsArray] = useState(null);

  const [selectedReceipt, setSelectedReceipt] = useState(null);

  const AcceptAgreement = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/acceptAgreements",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            signedAt: signedAt,
            email: email,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setLoading(false);
        setMessage(respnseData.success);
        setToastPop(true);
      } else {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const getPatientReceipts = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getReciptForPatient",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.customerUserData.userId,
            idNumber: auth.customerUserData.idDoc,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setReceiptsArray(respnseData.receipts);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getPatientReceipts();
  }, [recieptsPopUp]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: "#000000cc",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        //   justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          color: BlackAndWhite.secondary,
          minHeight: selectedDisplay ? 215 : 120,
          height: selectedDisplay ? 215 : 120,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          // backgroundColor: BlackAndWhite.primary,
        }}
      >
        <div
          style={{
            width: "100%",
            paddingBottom: 10,
            paddingTop: 10,
            backgroundColor: BlackAndWhite.secondary,
            color: "#000000",
            marginBottom: 10,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            // minHeight: 40,
          }}
        >
          <div
            style={{
              marginLeft: 30,
              fontSize: 18,
              marginTop: 20,
              fontWeight: "bold",
            }}
          >
            PROFILE
          </div>
        </div>
        <div
          style={{
            flex: 0.6,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            // marginTop: 10,
          }}
        >
          <img
            alt="Edit"
            src={require("../../../../../../images/material-icons/profileWhite.png")}
            style={{
              height: 55,
              marginBottom: 10,
              marginLeft: 20,
              marginTop: 20,
            }}
          />

          <div
            style={{
              width: 250,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 80,
              fontSize: 18,
              marginLeft: 20,
              flexDirection: "column",
              position: "relative",
              marginBottom: 10,
            }}
          >
            {customerName}
            {auth ? (
              auth.customerUserData.status ? (
                <div
                  onClick={
                    auth.customerUserData.status === "Active"
                      ? null
                      : () => setValidateEdit(true)
                  }
                  style={{
                    // position: "absolute",
                    // bottom: 0,
                    // right: 0,
                    width: 150,
                    fontSize: 14,
                    borderStyle: "solid",
                    height: 30,
                    borderRadius: 8,
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#48A14D",
                    borderWidth: 0.5,
                    // marginLeft: 50,
                  }}
                >
                  Status: {auth.customerUserData.status}
                </div>
              ) : (
                <div
                  onClick={() => setValidateEdit(true)}
                  style={{
                    // position: "absolute",
                    // bottom: 0,
                    // right: 0,
                    width: 180,
                    fontSize: 14,
                    borderStyle: "solid",
                    height: 30,
                    borderRadius: 8,
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#B33F40",
                    borderWidth: 0.5,
                  }}
                >
                  Validate Account
                </div>
              )
            ) : (
              <div
                onClick={() => setValidateEdit(true)}
                style={{
                  // position: "absolute",
                  // bottom: 0,
                  // right: 0,
                  width: 180,
                  fontSize: 14,
                  borderStyle: "solid",
                  height: 30,
                  borderRadius: 8,
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#48A14D",
                }}
              >
                Account Validated
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            backgroundColor: BlackAndWhite.primary,
            borderRadius: "100%",
            zIndex: 100000000000000000,
          }}
        >
          <TabMobileButton
            icon={
              loading
                ? require("../../../../../../images/material-icons/loadingWhte.png")
                : require("../../../../../../images/material-icons/refreshwhite.png")
            }
            onClick={() => {
              refreshHandler();
            }}
            height={40}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          minHeight: 120,

          marginTop: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            width: "90%",
            minHeight: "90%",
            height: "90%",
            backgroundColor: BlackAndWhite.secondary,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 0.6,

              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ fontSize: 24, fontWeight: "bold", marginBottom: 5 }}
              >
                {auth
                  ? Number(auth.customerUserData.rewardPoints).toFixed(2)
                  : "loading"}
              </div>
              <div style={{ fontWeight: "bold", fontSize: 14, opacity: 0.5 }}>
                STORE CREDIT
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 0.4,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BaseButtons
              mini={true}
              label={"BUY MORE"}
              disable={true}
              borderRadius={8}
              marginTop={-1}
              size={"90%"}
              height={35}
            />
            <BaseButtons
              mini={true}
              label={"RECEIPTS"}
              // disable={true}
              borderRadius={8}
              marginTop={15}
              size={"90%"}
              height={35}
              onClick={() => setRecieptsPopUp(true)}
            />
          </div>
        </div>
      </div>

      <FormFields
        selectedDisplay={selectedDisplay}
        patientNumber={patientNumber}
        firstName={firstName}
        lastName={lastName}
        idDoc={idDoc}
        dateOfBirth={dateOfBirth}
        cellNumber={cellNumber}
        email={email}
        profileProgress={profileProgress}
        accountType={accountType}
        onTermsClick={() => setViewTerms(true)}
        agreed={false}
        termsBackgroundColorButton={
          auth
            ? agreements
              ? agreements.length !== 0
                ? BlackAndWhite.secondary
                : "red"
              : "red"
            : "red"
        }
      />
      <TermsDisplay
        terms={"social"}
        onCloseClick={() => setViewTerms(false)}
        display={viewTerms}
        isSigned={agreements ? (agreements.length !== 0 ? true : false) : false}
        userName={firstName + " " + lastName}
        signedAtAlready={
          auth
            ? agreements
              ? agreements.length !== 0
                ? agreements[0].signedAt
                : "loading"
              : "loading"
            : "loading"
        }
        isActive={
          auth
            ? agreements
              ? agreements.length !== 0
                ? true
                : false
              : false
            : false
        }
        dateCreated={
          auth
            ? agreements
              ? agreements.length !== 0
                ? agreements[0].dateCreated
                : "loading"
              : "loading"
            : "loading"
        }
        signedAt={signedAt}
        setSignedAt={setSignedAt}
        onAcceptClick={() => {
          AcceptAgreement();
          setViewTerms(false);
        }}
      />
      <ModalLayout
        display={recieptsPopUp}
        mobile={true}
        page={
          <div
            style={{
              flex: 1,
              backgroundColor: BlackAndWhite.secondary,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              // borderBottomRightRadius: 20,
              // borderBottomLeftRadius: 20,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              <CloseButton
                label={"X"}
                onClick={() => setRecieptsPopUp(false)}
              />
            </div>
            <div
              style={{
                width: "100%",
                fontSize: 20,
                textAlign: "center",
                minHeight: 75,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>TRANSACTION HISTORY</div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              {receiptsArray ? (
                receiptsArray.map((receipt, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => setSelectedReceipt(receipt)}
                        style={{
                          minHeight: 160,
                          maxHeight: 160,
                          width: "90%",
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: 10,
                          overflow: "hidden",
                          WebkitBoxShadow: "0px 0px 7px 2px #00000030",
                        }}
                      >
                        <div style={{ marginLeft: 20, marginTop: 20 }}>
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              opacity: 0.5,
                              marginBottom: 3,
                            }}
                          >
                            SLIP NO
                          </div>
                          <div style={{ fontSize: 14 }}>{receipt.slipNo}</div>
                        </div>
                        <div style={{ marginLeft: 20, marginTop: 10 }}>
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              opacity: 0.5,
                              marginBottom: 3,
                            }}
                          >
                            STORE NAME
                          </div>
                          <div style={{ fontSize: 14 }}>
                            {receipt.storeName}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            marginTop: 10,
                          }}
                        >
                          <div style={{ marginLeft: 20 }}>
                            <div
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                opacity: 0.5,
                                marginBottom: 3,
                              }}
                            >
                              CHANGE
                            </div>
                            <div style={{ fontSize: 16 }}>
                              R {Number(receipt.changeAmount).toFixed(2)}
                            </div>
                          </div>
                          <div style={{ flex: 1 }} />
                          <div style={{ marginRight: 20 }}>
                            <div
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                opacity: 0.5,
                                marginBottom: 3,
                              }}
                            >
                              TOTAL
                            </div>
                            <div style={{ fontSize: 16 }}>
                              R {Number(receipt.total).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center", marginTop: 20 }}>
                  LOADING
                </div>
              )}
            </div>
          </div>
        }
      />
      <ModalLayout
        display={selectedReceipt}
        mobile={true}
        page={
          <div
            style={{
              flex: 1,
              backgroundColor: BlackAndWhite.secondary,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              // borderBottomRightRadius: 20,
              // borderBottomLeftRadius: 20,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              <CloseButton
                label={"X"}
                onClick={() => setSelectedReceipt(null)}
              />
            </div>
            <div
              style={{
                width: "100%",
                fontSize: 20,
                textAlign: "center",
                minHeight: 75,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>RECEIPT HISTORY</div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div style={{ marginTop: 20, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  SLIP NO
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt ? selectedReceipt.slipNo : "loading"}
                </div>
              </div>
              <div style={{ marginTop: 15, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  STORE
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt ? selectedReceipt.storeName : "loading"}
                </div>
              </div>
              <div style={{ marginTop: 15, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  ADDRESS
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt ? selectedReceipt.Address : "loading"}
                </div>
              </div>
              <div style={{ marginTop: 15, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  CONTACT NUMBER
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt ? selectedReceipt.contactNumber : "loading"}
                </div>
              </div>
              <div style={{ marginTop: 15, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  CASHIER
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt ? selectedReceipt.name : "loading"}
                </div>
              </div>
              <div style={{ marginTop: 15, width: "85%" }}>
                <div
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.5,
                    marginBottom: 3,
                  }}
                >
                  DATE & TIME
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedReceipt
                    ? selectedReceipt.dateTime.substring(0, 10) +
                      " at " +
                      selectedReceipt.dateTime.substring(11, 16)
                    : "loading"}
                </div>
              </div>

              <div style={{ marginTop: 25, width: "85%" }}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    opacity: 0.9,
                    marginBottom: 3,
                  }}
                >
                  PURCHASED ITEMS
                </div>
              </div>
              {selectedReceipt
                ? selectedReceipt.productsArray.map((product, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "90%",
                          minHeight: 100,
                          marginTop: index === 0 ? 20 : 30,
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 8,
                          WebkitBoxShadow: "0px 0px 7px 2px #00000030",
                        }}
                      >
                        <div style={{ marginLeft: 10 }}>
                          <img
                            style={{ height: 60, width: 60 }}
                            alt="icon"
                            src={product ? product.product.imageUrl : null}
                          />
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 120,
                            width: 120,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              opacity: 0.5,
                              marginBottom: 3,
                              width: "100%",
                            }}
                          >
                            PRODUCT NAME
                          </div>
                          <div style={{ fontSize: 14, width: "100%" }}>
                            {product ? product.product.productName : null}
                          </div>
                        </div>
                        <div
                          style={{
                            marginLeft: 20,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              opacity: 0.5,
                              marginBottom: 3,
                            }}
                          >
                            QTY
                          </div>
                          <div style={{ fontSize: 14 }}>
                            {product ? product.qtyCard.qty : null}
                          </div>
                        </div>
                        <div style={{ flex: 1 }} />
                        <div
                          style={{
                            marginRight: 20,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              fontWeight: "bold",
                              opacity: 0.5,
                              marginBottom: 3,
                            }}
                          >
                            TOTAL
                          </div>
                          <div style={{ fontSize: 14 }}>
                            R{" "}
                            {product
                              ? Number(
                                  product.product.retailPriceInc *
                                    product.qtyCard.qty
                                ).toFixed(2)
                              : null}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : "loading"}

              <div style={{ marginTop: 30, width: "85%", display: "flex" }}>
                <div style={{ flex: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      opacity: 0.5,
                      // marginBottom: 3,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    SUB-TOTAL :
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>
                    {selectedReceipt
                      ? "R " + Number(selectedReceipt.subTotal).toFixed(2)
                      : "loading"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 25,
                  width: "85%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      opacity: 0.5,
                      // marginBottom: 3,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    VAT :
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>
                    {selectedReceipt
                      ? "R " + Number(selectedReceipt.tax).toFixed(2)
                      : "loading"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: 25,
                  width: "85%",
                  display: "flex",
                  marginBottom: 20,
                }}
              >
                <div style={{ flex: 1 }} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      opacity: 0.5,
                      // marginBottom: 3,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    TOTAL :
                  </div>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>
                    {selectedReceipt
                      ? "R " + Number(selectedReceipt.total).toFixed(2)
                      : "loading"}
                  </div>
                </div>
              </div>
              {/* <div style={{ marginTop: 25, width: "85%" }}>
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    opacity: 0.9,
                    marginBottom: 3,
                  }}
                >
                  PAYMENTS
                </div>
              </div> */}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Profile;
