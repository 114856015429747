import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons } from "../../../../components/atoms";

const TrumdDisplay = () => {
  const [displayList, setDisplayList] = useState("full list");

  const [first200List, setFirst200List] = useState([]);
  const [restOfList, setRestOfList] = useState([]);

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);

  const getAllRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/fetchCompleteList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({
          //     firstName: auth.customerUserData.firstName,
          //     lastName: auth.customerUserData.lastName,
          //     cellNumber: auth.customerUserData.cellNumber,
          //     email: auth.customerUserData.email,
          //     idDoc: auth.customerUserData.idDoc,
          //     gender: auth.customerUserData.gender,
          //     suburb: auth.customerUserData.suburb,
          //     town: auth.customerUserData.town,
          //     street: auth.customerUserData.street,

          //     dateOfBirth: auth.customerUserData.dateOfBirth,
          //   }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setFirst200List(respnseData.first200);
        setRestOfList(respnseData.theRest);
        setLoading(false);
        setMessage(
          "Success, your request has been sent a doctor will contact you"
        );
        setToastPop(true);
      } else {
        setLoading(false);
        setMessage("faluire, could not create you request contact support");
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("failure" + `${err}`);
      setToastPop(true);
      console.log(err);
    }
  };
  useEffect(() => {
    getAllRequests();
  }, []);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        height: "97%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <div
        style={{
          height: 40,
          width: "100%",
          backgroundColor: BlackAndWhite.secondary,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ marginLeft: 20 }}>
          {displayList === "full list"
            ? "All users after first 200"
            : "First 200 section 21 requests"}
        </div>
        <BaseButtons
          // marginTopFalse={true}
          marginLeft={20}
          marginTop={-5}
          mini={true}
          height={30}
          borderRadius={4}
          label={"DOWNLOAD LIST"}
        />
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: "flex",
            marginRight: 20,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BaseButtons
            mini={true}
            height={30}
            borderRadius={4}
            label={"FIRST 200"}
            invert={displayList === "full list" ? true : false}
            marginRight={10}
            marginTop={-5}
            onClick={() => setDisplayList("first200")}
          />
          <BaseButtons
            // marginTopFalse={true}
            marginTop={-5}
            mini={true}
            height={30}
            borderRadius={4}
            label={"FULL LIST"}
            invert={displayList === "full list" ? false : true}
            onClick={() => setDisplayList("full list")}
          />
        </div>
      </div>
      {/* // header */}
      <div
        style={{
          height: 40,
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 0.15,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>FIRST NAME</div>
        </div>
        <div
          style={{
            flex: 0.15,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>LAST NAME</div>
        </div>
        <div
          style={{
            flex: 0.15,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>CELL NUMBER</div>
        </div>
        <div
          style={{
            flex: 0.2,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>EMAIL</div>
        </div>
        <div
          style={{
            flex: 0.15,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>ID NUMBER</div>
        </div>
        <div
          style={{
            flex: 0.2,
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 12,
            color: BlackAndWhite.secondary,
          }}
        >
          <div style={{ marginLeft: 10 }}>ADDRESS</div>
        </div>
      </div>
      {/* // download button */}

      {/* //list */}
      <div
        style={{
          flex: 1,

          width: "99.8%",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {displayList === "full list"
          ? restOfList.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    borderStyle: "solid",
                    minHeight: 40,
                    display: "flex",
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    borderColor: "#00000040",
                    alignItems: "center",
                    borderLeftStyle: "none",
                    borderTopStyle: "none",
                    borderRightStyle: "none",
                  }}
                >
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.firstName}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.lastName}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.cellnumber}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.email}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.idDoc}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>
                      {item.street + ", " + item.suburb + ", " + item.town}
                    </div>
                  </div>
                </div>
              );
            })
          : first200List.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    borderStyle: "solid",
                    minHeight: 40,
                    display: "flex",
                    borderBottomStyle: "solid",
                    borderBottomWidth: 1,
                    borderColor: "#00000040",
                    alignItems: "center",
                    borderLeftStyle: "none",
                    borderTopStyle: "none",
                    borderRightStyle: "none",
                  }}
                >
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.firstName}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.lastName}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.cellnumber}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.email}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.15,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>{item.idDoc}</div>
                  </div>
                  <div
                    style={{
                      flex: 0.2,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      fontSize: 12,
                      color: BlackAndWhite.primary,
                    }}
                  >
                    <div style={{ marginLeft: 10 }}>
                      {item.street + ", " + item.suburb + ", " + item.town}
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default TrumdDisplay;
