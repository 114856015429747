import React, { useState, useEffect } from "react";
import {
  ExchangeItem,
  ReceiptItem,
  RefundItem,
  SalesHistoryFilter,
  SalesHistoryHeader,
  TransactionDisplay,
} from "./components";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";
import { LoadingPopUp } from "../../../../components";
import NumberSpace from "../../../global-functions/NumberSpace";

const SalesHistory = ({
  user,
  refresh,
  setLoading,

  setToastPop,
  setMessage,
  setRefresh,
  storeInfo,
  setStoreInfo,
  cashRegisterOpenCheck,
}) => {
  const [filter, setFilter] = useState(null);
  const [search, setSearch] = useState("");
  const [viewTransactionDisplay, setViewTransactionDisplay] = useState(false);
  const [selectedTransactionDisplay, setSelectedTransactionDisplay] =
    useState(false);

  const [receiptOptionView, setReceiptOptionView] = useState("reciepts");
  const [optionDropDown, setOptionDropDown] = useState(false);

  const [reciepts, setReciepts] = useState([]);
  const [exchanges, setExchanges] = useState([]);
  const [refunds, setrefunds] = useState([]);

  let arrayCheck = reciepts;
  let arrayExchangeCheck = exchanges;
  let arrayRefundCheck = refunds;

  const GetStoreReceipts = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getStoreRecipets",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            storeId: storeInfo.storeId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setReciepts(responseData.receipts.reverse());
        setExchanges(responseData.exchanges.reverse());
        setrefunds(responseData.refunds.reverse());
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  // getStoreRecipets
  useEffect(() => {
    GetStoreReceipts();
  }, [refresh]);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        style={{
          minHeight: 60,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            // width: 300,
            marginBottom: 5,
            borderWidth: 0.5,
          }}
        >
          <TextInputFieldSearch
            placeholder={"type here to search"}
            height={30}
            borderRadius={4}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div
            style={{
              minHeight: 30,
              height: 35,
              width: 70,
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 4,
              display: "flex",
              color: BlackAndWhite.secondary,
              justifyContent: "center",
              alignItems: "center",
              marginRight: 20,
              marginLeft: 5,
              // marginBottom: 5,
            }}
          >
            <div
              onClick={() => setSearch("")}
              style={{ fontSize: 12, fontWeight: "bold" }}
            >
              CLEAR
            </div>
          </div>
        </div>
        {/* <div style={{ marginBottom: 5 }}>
          <BaseButtons
            label={"REFRESH"}
            mini={true}
            marginTopFalse={true}
            height={35}
            borderRadius={4}
            size={120}
            onClick={() => setRefresh(refresh ? false : true)}
          />
        </div> */}

        <div
          style={{
            marginLeft: 20,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <BaseButtons
            label={receiptOptionView.toLocaleUpperCase()}
            mini={true}
            marginTop={-1}
            height={35}
            size={100}
            borderRadius={4}
            onClick={() => setOptionDropDown(true)}
          />
          {optionDropDown ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: BlackAndWhite.secondary,
                padding: 10,
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                borderRadius: 8,
              }}
            >
              <BaseButtons
                label={"RECEIPTS"}
                mini={true}
                marginTop={-1}
                height={35}
                size={100}
                borderRadius={4}
                onClick={() => {
                  setOptionDropDown(false);
                  setReceiptOptionView("reciepts");
                }}
              />
              <BaseButtons
                label={"REFUNDS"}
                mini={true}
                marginTop={5}
                height={35}
                size={100}
                borderRadius={4}
                onClick={() => {
                  setOptionDropDown(false);
                  setReceiptOptionView("refunds");
                }}
              />
              <BaseButtons
                label={"EXCHANGES"}
                mini={true}
                marginTop={5}
                height={35}
                size={100}
                borderRadius={4}
                onClick={() => {
                  setOptionDropDown(false);
                  setReceiptOptionView("exchanges");
                }}
              />
            </div>
          ) : null}
        </div>
        <div style={{ flex: 1 }} />

        <SalesHistoryFilter filterName={"FILTER"} setScreen={setFilter} />
      </div>
      <SalesHistoryHeader />
      <div
        style={{
          flex: 1,
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          maxHeight: 800,
          minHeight: 400,
        }}
      >
        {receiptOptionView === "reciepts" ? (
          reciepts.length >= 1 ? (
            arrayCheck.map((reciepts, index) => {
              return String(
                reciepts.slipNo +
                  reciepts.customerName +
                  reciepts.dateTime.substring(0, 10) +
                  reciepts.customerName +
                  reciepts.name
              )
                .toLocaleLowerCase()
                .match(search.toLocaleLowerCase()) ? (
                <div key={index} style={{ width: "100%" }}>
                  <ReceiptItem
                    id={reciepts.slipNo}
                    date={
                      reciepts.dateTime.substring(0, 10) +
                      " at " +
                      new Date(reciepts.dateTime).toLocaleTimeString()
                    }
                    amount={Number(reciepts.total).toFixed(2)}
                    itemsQty={reciepts.productsArray.length}
                    createdBy={reciepts.name}
                    onManageClick={() => {
                      setViewTransactionDisplay(true);
                      setSelectedTransactionDisplay(reciepts);
                    }}
                    customer={reciepts.customerName}
                  />
                </div>
              ) : null;
            })
          ) : (
            <div style={{ marginTop: 100, width: "100%", textAlign: "center" }}>
              NO DATA IS AVALIBLE{" "}
            </div>
          )
        ) : null}
        {receiptOptionView === "exchanges" ? (
          exchanges.length >= 1 ? (
            arrayExchangeCheck.map((reciepts, index) => {
              return String(reciepts._id)
                .toLocaleLowerCase()
                .match(search.toLocaleLowerCase()) ? (
                <div key={index} style={{ width: "100%" }}>
                  <ReceiptItem
                    id={reciepts.slipNoLinkedTo}
                    date={
                      reciepts.dateTime.substring(0, 10) +
                      " at " +
                      new Date(reciepts.dateTime).toLocaleTimeString()
                    }
                    amount={Number(reciepts.total).toFixed(2)}
                    itemsQty={reciepts.productsArray.length}
                    createdBy={reciepts.exchangedBy}
                    onManageClick={() => {
                      setViewTransactionDisplay(true);
                      setSelectedTransactionDisplay(reciepts);
                    }}
                    customer={reciepts.customerName}
                  />
                </div>
              ) : null;
            })
          ) : (
            <div style={{ marginTop: 100, width: "100%", textAlign: "center" }}>
              NO DATA IS AVALIBLE{" "}
            </div>
          )
        ) : null}
        {receiptOptionView === "refunds" ? (
          refunds.length >= 1 ? (
            arrayRefundCheck.map((reciepts, index) => {
              return String(reciepts._id)
                .toLocaleLowerCase()
                .match(search.toLocaleLowerCase()) ? (
                index <= 50 ? (
                  <div key={index} style={{ width: "100%" }}>
                    <ReceiptItem
                      id={reciepts._id}
                      date={
                        reciepts.dateTime.substring(0, 10) +
                        " at " +
                        new Date(reciepts.dateTime).toLocaleTimeString()
                      }
                      amount={Number(reciepts.refundedTotal).toFixed(2)}
                      itemsQty={reciepts.productsArray.length}
                      createdBy={reciepts.refundedBy}
                      customer={reciepts.customerName}
                      onManageClick={() => {
                        setViewTransactionDisplay(true);
                        setSelectedTransactionDisplay(reciepts);
                      }}
                    />
                  </div>
                ) : null
              ) : null;
            })
          ) : (
            <div style={{ marginTop: 100, width: "100%", textAlign: "center" }}>
              NO DATA IS AVALIBLE{" "}
            </div>
          )
        ) : null}
      </div>
      <TransactionDisplay
        display={viewTransactionDisplay}
        setViewTransactionDisplay={setViewTransactionDisplay}
        selectedTransactionDisplay={selectedTransactionDisplay}
        setSelectedTransactionDisplay={setSelectedTransactionDisplay}
        user={user.adminUserData}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        storeInfo={storeInfo}
        receiptOptionView={receiptOptionView}
        cashRegisterOpenCheck={cashRegisterOpenCheck}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default SalesHistory;
