import React, { useState, useEffect } from "react";
import { BaseButtons } from "../../../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const TransferItem = ({ SelectTranfersForView, doc }) => {
  const [storeInfo, setStoreInfo] = useState(null);
  const [hover, setHover] = useState(false);

  const GetStoreInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreInfo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: doc ? doc.to : "",
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreInfo(responseData.storeFound);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (doc.to !== "warehouse") {
      GetStoreInfo();
    }
  }, []);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        display: "flex",
        height: "100%",
        backgroundColor: hover ? "#00000030" : null,
        // color: hover ? BlackAndWhite.secondary : null,
        cursor: "pointer",
        minHeight: 50,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", fontSize: 12 }}>
          {" "}
          {doc.transferId}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%", fontSize: 12 }}>
          {" "}
          {doc.dateAdded.substring(0, 10) +
            " " +
            doc.dateAdded.substring(11, 16)}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}>{doc.from}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          overflow: "hidden",
        }}
      >
        <div style={{ width: "95%" }}>
          {storeInfo
            ? storeInfo.storeName
            : doc.to === "warehouse"
            ? "warehouse"
            : "loading"}
        </div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "70%", overflowX: "hidden" }}>
          {doc.productsArray.length}
        </div>
      </div>
      <div
        style={{
          flex: 0.2,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          {doc.createdBy}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <BaseButtons
          label={"VIEW"}
          mini={true}
          height={30}
          size={120}
          borderRadius={8}
          marginTopFalse={true}
          onClick={() => SelectTranfersForView(doc)}
        />
      </div>
    </div>
  );
};

export default TransferItem;
