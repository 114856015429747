import React, { useState, useEffect, useContext, useRef } from "react";
import useWindowDimensions from "../../../../components/get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../../themes/Colors";
import { AdminContext } from "../../../../context/admin-context";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../components/atoms";
import { DropDownOwners } from "./components";

const FacilityPopUp = ({
  display,
  width,
  height,
  onCloseClick,
  deleteOption,
  selectedFacility,
  setLoading,
  setMessage,
  setToastPop,
  setOpenFacilityDisplay,
  setIsDeleting,
  updateListFetch,
  Street,
  setImageLInk,
  setSubSelection,
  ownerArray,
}) => {
  const [ownerName, setOwnerName] = useState("");
  const [ownerId, setOwnerId] = useState("");

  const [imageUploadHover, setImageUploadHover] = useState(false);

  const admin = useContext(AdminContext);

  const [selectedType, setSelectedType] = useState("none");
  const [facilitiesArray, setFacilitiesArray] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [completedPopUp, setCompletePopUp] = useState(false);
  // const [ownerArray, setOwnerArrray] = useState([]);

  const [error, setError] = useState("error");

  const [completed, setCompleted] = useState(0);

  //image info and state
  const [file, setFile] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const filepickerRef = useRef();

  // register facilties fields

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [landLine, setLandLine] = useState("");
  const [type, setType] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [province, setProvince] = useState("none");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storeName, setStoreName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressName, setStreetAddressName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");

  const [para1, setPara1] = useState("none");
  const [para2, setPara2] = useState("nothing");

  const [dayTo, setDayTo] = useState("");
  const [dayFrom, setDayFrom] = useState("");
  const [endTo, setEndTo] = useState("");
  const [endFrom, setEndFrom] = useState("");
  const [publicTo, setPublicTo] = useState("");
  const [publicFrom, setPublicFrom] = useState("");

  const [locationLink, setLoactionLink] = useState("");

  const [monday, setMonday] = useState("");
  const [tuesday, setTuesday] = useState("");
  const [wednesday, setWednesday] = useState("");
  const [thursday, setThurday] = useState("");
  const [friday, setFriday] = useState("");
  const [saturday, setSaturday] = useState("");
  const [sunday, setSunday] = useState("");
  const [publicTimes, setPublicTimes] = useState("");

  const [remunirationPercentage, setRemunirationPercentage] = useState("");

  const [imageLink, setImageLink] = useState("");
  // selected item display

  const [addFacilityPopUp, setAddFacilityPopUp] = useState(false);

  const [search, setSearch] = useState("");

  const [mondayTo, setMondayTo] = useState("");
  const [tuesdayTo, setTuesdayTo] = useState("");
  const [wedTo, setWedTo] = useState("");
  const [thurTo, setThurTo] = useState("");
  const [friTo, setFriTo] = useState("");
  const [satTo, setSatTo] = useState("");
  const [sunTo, setSunTo] = useState("");
  const [pubTo, setPubTo] = useState("");

  const [ownerSlectedObject, setOwnerSlectedObject] = useState("");
  const [ownerPopUp, setOwnerPopUp] = useState(false);
  const [statusValue, setStatusValue] = useState("");

  const ResetFields = () => {
    setStoreName("");
    setStreetAddress("");
    setSuburb("");
    setCity("");
    setPostCode("");
    setProvince("");
    setLandLine("");
    setCellNumber("");
    setEmail("");
    setType("");
    setPara1("");
    setPara2("");
  };

  const deleteFacility = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/deleteStore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: selectedFacility.storeId,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.message === "facility was deleted") {
        const wait = await updateListFetch();
        setLoading(false);
        setToastPop(true);
        setMessage(
          "Facility" + " " + selectedFacility.storeName + " was deleted."
        );
        setOpenFacilityDisplay(false);
        setIsDeleting(false);
      } else {
        setLoading(false);
        setToastPop(true);
        setOpenFacilityDisplay(false);
        setIsDeleting(false);
        setMessage("A error has occured, try again later");
      }
    } catch (err) {
      setOpenFacilityDisplay(false);
      setIsDeleting(false);
      setLoading(false);
      setToastPop(true);
      setMessage("there was a error deleteing your facilty trya again later");
    }
  };

  const ManageFacility = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/editFacility",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: selectedFacility.storeId,
            storeName: storeName,
            locationLink: locationLink,
            unitNumber: streetAddress,
            streetAddress: streetAddressName,
            suburb: suburb,
            city: city,
            postalCode: postCode,
            province: province,
            landLine: landLine,
            cellNumber: cellNumber,
            email: email,
            type: type,
            monday: monday + " - " + mondayTo,
            tuesday: tuesday + " - " + tuesdayTo,
            wednesday: wednesday + " - " + wedTo,
            thursday: thursday + " - " + thurTo,
            friday: friday + " - " + friTo,
            saturday: saturday + " - " + satTo,
            sunday: sunday + " - " + sunTo,
            publicHoliday: publicTimes + " - " + pubTo,
            createdDate: new Date().toDateString(),
            lastEdited: new Date().toDateString(),
            editedBy:
              admin.adminUserData.firstName +
              " " +
              admin.adminUserData.lastName,
            descriptionP1: para1,
            descriptionp2: para2,
            image: imageLink,
            ownerId: ownerSlectedObject ? ownerSlectedObject._id : ownerId,
            ownerName: ownerSlectedObject
              ? ownerSlectedObject.firstName + " " + ownerSlectedObject.lastName
              : ownerName,
            remunirationPercentage: remunirationPercentage,
            status: statusValue || "Pending",
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.message === "facility was updated") {
        const wait = await updateListFetch();
        setSelectedOwner("");
        setCompletePopUp(false);
        setOpenFacilityDisplay(false);
        setLoading(false);
        setToastPop(true);

        setMessage("Your Facilty was updated");

        ResetFields();
      } else {
        setToastPop(true);
        setMessage("Unable to create facilty" + " ," + respnseData.message);
        setError("invalid credentials");
        setLoading(false);
      }
    } catch (err) {
      setToastPop(true);
      setMessage("Unable to create facilty");
      setError("invalid credentials");
      setLoading(false);
    }
  };
  const checkOwner = async () => {
    setOwnerSlectedObject(null);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/getStoresByOwner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ownerId: selectedFacility.ownerId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.store) {
        const value =
          respnseData.store.firstName + " " + respnseData.store.lastName;
        setOwnerName(value);
        setOwnerId(respnseData.store._id);
      }
    } catch (err) {
      setOwnerName("no owner");
    }
  };

  useEffect(() => {
    checkOwner();
  }, [selectedFacility]);
  useEffect(() => {
    if (selectedFacility) {
      const mondayFrom = selectedFacility.monday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const tuesdayFrom = selectedFacility.tuesday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const mondayTo = selectedFacility.monday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const tuesdayTo = selectedFacility.tuesday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const wednesdayFrom = selectedFacility.wednesday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const wednesdayTo = selectedFacility.wednesday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const thursdayFrom = selectedFacility.thursday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const thursdayTo = selectedFacility.thursday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const fridayFrom = selectedFacility.friday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const fridayTo = selectedFacility.friday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const saturdayFrom = selectedFacility.saturday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const saturdayTo = selectedFacility.saturday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const sundayFrom = selectedFacility.sunday
        .substring(0, 5)
        .replace(/\\|\//g, "");
      const sundayTo = selectedFacility.sunday
        .substring(8, 16)
        .replace(/\\|\//g, "");
      const pubFrom = selectedFacility.publicHoliday.substring(0, 5);
      const pubTo = selectedFacility.publicHoliday.substring(8, 144);

      setStoreName(selectedFacility.storeName);
      setCellNumber(selectedFacility.cellNumber);
      setLandLine(selectedFacility.landLine);
      setEmail(selectedFacility.email);
      setStreetAddress(selectedFacility.unitNumber);
      setLoactionLink(selectedFacility.locationLink);
      setStreetAddressName(selectedFacility.streetAddress);
      setStreetAddress(selectedFacility.unitNumber);
      setSuburb(selectedFacility.suburb);
      setCity(selectedFacility.city);
      setPostCode(selectedFacility.postalCode);
      setProvince(selectedFacility.province);
      setType(selectedFacility.type);
      setPara1(selectedFacility.descriptionP1);
      setPara2(selectedFacility.descriptionp2);
      setImageLink(selectedFacility.image);
      setMonday(mondayFrom);
      setMondayTo(mondayTo);
      setTuesday(tuesdayFrom);
      setTuesdayTo(tuesdayTo);
      setWednesday(wednesdayFrom);
      setWedTo(wednesdayTo);
      setThurday(thursdayFrom);
      setThurTo(thursdayTo);
      setFriday(fridayFrom);
      setFriTo(fridayTo);
      setSaturday(saturdayFrom);
      setSatTo(saturdayTo);
      setSunday(sundayFrom);
      setSunTo(sundayTo);
      setPublicTimes(pubFrom);
      setPubTo(pubTo);
      setRemunirationPercentage(selectedFacility.remunirationPercentage);
      setStatusValue(selectedFacility.status);
    }
  }, [selectedFacility]);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.4s",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {deleteOption ? (
          <div
            style={{
              color: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>would you like to delete this Facility?</div>
            <div style={{ display: "flex" }}>
              <BaseButtons label={"NO"} onClick={onCloseClick} />
              <BaseButtons
                label={"YES, IM SURE"}
                onClick={() => deleteFacility()}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              flex: 1,

              width: "100%",
              overflow: "hidden",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                flex: 0.7,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                // height: "100%",
                // maxHeight: height > 700 ? 700 : 550,
                // minHeight: 550,
              }}
            >
              {/* primary informatioon */}
              <div
                style={{
                  height: 500,
                  width: "90%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                      marginBottom: 30,
                    }}
                  >
                    PRIMARY INFORMATION
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Store Name"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"90%"}
                  value={storeName}
                  onChange={(e) => setStoreName(e.target.value)}
                />
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div onClick={() => setOwnerPopUp(true)}>
                    <TextInputField
                      labelTitle={"Owner"}
                      mini={true}
                      invert={true}
                      width={"100%"}
                      size={"90%"}
                      readOnly={true}
                      value={
                        ownerSlectedObject
                          ? ownerSlectedObject.firstName +
                            " " +
                            ownerSlectedObject.lastName
                          : ownerName
                      }
                      // onChange={(e) => setStoreName(e.target.value)}
                    />
                  </div>
                  <DropDownOwners
                    data={ownerArray ? ownerArray.data : null}
                    display={ownerPopUp}
                    setOwnerPopUp={setOwnerPopUp}
                    setOwnerSlectedObject={setOwnerSlectedObject}
                  />
                </div>
                <TextInputField
                  labelTitle={"Store Cell Number"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"90%"}
                  value={cellNumber}
                  onChange={(e) => setCellNumber(e.target.value)}
                />
                <TextInputField
                  labelTitle={"Store Landline"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"90%"}
                  value={landLine}
                  onChange={(e) => setLandLine(e.target.value)}
                />
                <TextInputField
                  labelTitle={"Store Email"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"90%"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {/* secodary informatioon */}
              <div
                style={{
                  height: 520,
                  width: "90%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                    }}
                  >
                    ADDRESS INFORMATION | TYPE
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Google location link"}
                  mini={true}
                  invert={true}
                  size={"90%"}
                  width={"100%"}
                  value={locationLink}
                  onChange={(e) => setLoactionLink(e.target.value)}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <TextInputField
                    labelTitle={"Unit | Street Number"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"Street | Road Name"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={streetAddressName}
                    onChange={(e) => setStreetAddressName(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <TextInputField
                    labelTitle={"Suburb"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={suburb}
                    onChange={(e) => setSuburb(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"City"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <TextInputField
                    labelTitle={"Postal code"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"Province"}
                    mini={true}
                    invert={true}
                    size={"80%"}
                    width={"50%"}
                    value={province}
                    onChange={(e) => setProvince(e.target.value)}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <TextInputField
                    labelTitle={"Store Type"}
                    mini={true}
                    invert={true}
                    size={"90%"}
                    width={"100%"}
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>
              </div>
              {/* descriptions */}
              <div
                style={{
                  height: 420,
                  width: "90%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ width: "100%", display: "flex", marginBottom: 10 }}
                >
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                    }}
                  >
                    DESCRIPTIONS
                  </div>
                </div>
                <TextAreaInput
                  height={150}
                  labelTitle={"Paragraph one"}
                  color={BlackAndWhite.primary}
                  size={"90%"}
                  width={"100%"}
                  mini={true}
                  value={para1}
                  onChange={(e) => setPara1(e.target.value)}
                />
                <div style={{ height: 20 }} />
                <TextAreaInput
                  height={150}
                  labelTitle={"Paragraph Two"}
                  color={BlackAndWhite.primary}
                  size={"90%"}
                  width={"100%"}
                  mini={true}
                  value={para2}
                  onChange={(e) => setPara2(e.target.value)}
                />
              </div>

              <div style={{ marginTop: 50 }} />
            </div>
            <div
              style={{
                flex: 0.3,
                // borderStyle: "solid",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {/* images */}
              <div
                style={{
                  height: 375,
                  width: "95%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 10,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                    }}
                  >
                    IMAGES
                  </div>
                </div>
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 300,
                    marginTop: 20,
                    borderStyle: "solid",
                    marginBottom: 10,
                    borderWidth: 0.5,
                  }}
                >
                  <img
                    alt="icon"
                    src={imageLink}
                    style={{ height: "100%", width: "100%" }}
                  />
                </div>
                <TextInputField
                  labelTitle={"Image Link"}
                  mini={true}
                  invert={true}
                  size={"75%"}
                  width={"100%"}
                  onChange={(e) => setImageLink(e.target.value)}
                  value={imageLink}
                />
              </div>

              {/* Operating hours */}
              <div
                style={{
                  height: 645,
                  width: "95%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                    }}
                  >
                    OPERATING HOURS
                  </div>
                </div>
                {/* MOnday */}
                {/* <div style={{ width: "100%", textAlign: "left" }}>
                <div style={{ marginLeft: 20, marginTop: 10 }}>Monday</div>
              </div> */}
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Monday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={monday}
                    onChange={(e) => setMonday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={mondayTo}
                    onChange={(e) => setMondayTo(e.target.value)}
                    // marginTop={28}
                  />
                </div>
                {/* tuesday */}
                {/* <div style={{ width: "100%", textAlign: "left" }}>
                <div style={{ marginLeft: 20, marginTop: 10 }}>Tuesday</div>
              </div> */}
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Tuesday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={tuesday}
                    onChange={(e) => setTuesday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={tuesdayTo}
                    onChange={(e) => setTuesdayTo(e.target.value)}
                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Wednesday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={wednesday}
                    onChange={(e) => setWednesday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={wedTo}
                    onChange={(e) => setWedTo(e.target.value)}
                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Thursday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={thursday}
                    onChange={(e) => setThurday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={thurTo}
                    onChange={(e) => setThurTo(e.target.value)}
                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Friday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={friday}
                    onChange={(e) => setFriday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={friTo}
                    onChange={(e) => setFriTo(e.target.value)}

                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Saturday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={saturday}
                    onChange={(e) => setSaturday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={satTo}
                    onChange={(e) => setSatTo(e.target.value)}
                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Sunday: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    value={sunday}
                    onChange={(e) => setSunday(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    value={sunTo}
                    onChange={(e) => setSunTo(e.target.value)}

                    // marginTop={28}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <TextInputField
                    labelTitle={"Public holidays: "}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"e.g 08:00"}
                    height={20}
                    labelWidth={100}
                    value={publicTimes}
                    onChange={(e) => setPublicTimes(e.target.value)}
                  />
                  <TextInputField
                    labelTitle={"To"}
                    mini={true}
                    invert={true}
                    size={"65%"}
                    width={"50%"}
                    placeholder={"to e.g 20:00"}
                    height={20}
                    marginTop={28}
                    value={pubTo}
                    onChange={(e) => setPubTo(e.target.value)}
                  />
                </div>
              </div>
              <div
                style={{
                  height: 420,
                  width: "95%",
                  WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                  marginTop: 30,
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 20,
                      fontSize: 18,
                      fontWeight: "bold",
                      color: BlackAndWhite.primary,
                    }}
                  >
                    STORE REMUNIRATION
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Percentage:"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"80%"}
                  value={remunirationPercentage}
                  onChange={(e) => setRemunirationPercentage(e.target.value)}
                />
                <TextInputField
                  labelTitle={"Status:"}
                  mini={true}
                  invert={true}
                  width={"100%"}
                  size={"80%"}
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "59%",
                  }}
                >
                  <BaseButtons
                    label={"DELETE"}
                    onClick={() => setIsDeleting(true)}
                  />
                  <BaseButtons
                    label={"SAVE CHANGES"}
                    onClick={() => ManageFacility()}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <CloseButton onClick={onCloseClick} label={"X"} invert={false} />
        </div>
      </div>
    </div>
  );
};

export default FacilityPopUp;
