import React from "react";
import { BaseButtons } from "../../../../../../components/atoms";

const ReceiptItem = ({
  id,
  date,
  amount,
  itemsQty,
  createdBy,
  onManageClick,
  customer,
}) => {
  return (
    <div style={{ width: "100%", display: "flex", height: 50 }}>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 15, width: "100%" }}> {date}</div>
      </div>
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}> {id}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ width: "95%" }}> {createdBy}</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ width: "95%" }}> {customer}</div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ width: "95%" }}> R {amount}</div>
      </div>

      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          {itemsQty}
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
            width: "100%",
            // paddingRight: 10,
          }}
        >
          <BaseButtons
            label={"VIEW"}
            borderRadius={8}
            mini={true}
            height={30}
            marginTopFalse={true}
            size={100}
            onClick={onManageClick}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceiptItem;
