import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";
import JsPDF from "jspdf";
import { usePDF } from "react-to-pdf";
import generatePDF, { Resolution, Margin } from "react-to-pdf";

const ProductKnowledgeDisplayModal = ({
  display,
  onCloseClick,
  selectedProductDisplay,
}) => {
  const [selectedDescription, setSelectedDescription] = useState("quick");
  const [labelDisplay, setLabelDisplay] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const backgroundImage = require("./Michael_Template.png");
  const [typeCheckValue, setTypeCheckValue] = useState("");
  const [nameSpace1, setNameSpace1] = useState(0);
  const [nameSpace2, setNameSpace2] = useState(0);
  const [nameSpace3, setNameSpace3] = useState(0);

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        (product.thcType === "None" ? "" : product.thcType) +
        (product.thcType === "None" ? "" : " | ") +
        (product.flavour === "None" ? "" : product.flavour) +
        (product.flavour === "None" ? "" : " | ") +
        product.thcContent;
      return name;
    }
  };

  const { toPDF, targetRef } = usePDF({
    filename:
      NameAssemble({ product: selectedProductDisplay }) + " " + "label.pdf",
  });

  const checkType = () => {
    if (
      selectedProductDisplay.productSubType === "FSC Balanced Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Balanced Hybrid"
    ) {
      return "BALANCED";
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Sativa" ||
      selectedProductDisplay.productSubType === "FSC Sativa Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Sativa Hybrid"
    ) {
      return "SATIVA";
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Indica" ||
      selectedProductDisplay.productSubType === "FSC Indica Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Indica Hybrid"
    ) {
      return "INDICA";
    } else {
      return "BALANCE";
    }
  };

  const nameLength = () => {
    if (selectedProductDisplay.productName.length >= 18) {
      return {
        fontsize: 30,
        marginLeft: selectedProductDisplay.productName.length >= 17 ? 52.5 : 65,
      };
    } else {
      return { fontsize: 40, marginLeft: 75 };
    }
  };
  let NameSizeCaps = selectedProductDisplay
    ? selectedProductDisplay.productName.toLocaleUpperCase()
    : "loading";
  const TextSplitterResponse = async () => {
    if (selectedProductDisplay) {
      let name = NameSizeCaps;
      const nameArray = await name.split(" ");

      if (nameArray.length <= 1) {
        setNameSpace1(nameArray[0]);
        setNameSpace2(null);
        setNameSpace3(null);
        return nameArray;
      } else if (nameArray.length <= 2) {
        setNameSpace1(nameArray[0]);
        setNameSpace2(nameArray[1]);
        setNameSpace3(null);
        return nameArray;
      } else if (nameArray.length <= 3) {
        setNameSpace1(nameArray[0]);
        setNameSpace2(nameArray[1]);
        setNameSpace3(nameArray[2]);
        return nameArray;
      }
    }
  };

  const checkTypeSpacing = () => {
    let spacing = {
      x: 75,
      y: 186,
      xOffset: 75,
      yOffset: 186,
      xOffsetNeg: 75,
      yOffsetNeg: 187,
    };
    if (
      selectedProductDisplay.productSubType === "FSC Balanced Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Balanced Hybrid"
    ) {
      return (spacing = {
        x: 75,
        y: 206,
        xOffset: 75,
        yOffset: 206,
        xOffsetNeg: 75,
        yOffsetNeg: 206,
      });
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Sativa" ||
      selectedProductDisplay.productSubType === "FSC Sativa Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Sativa Hybrid"
    ) {
      return (spacing = {
        x: 75,
        y: 196,
        xOffset: 75,
        yOffset: 196,
        xOffsetNeg: 75,
        yOffsetNeg: 197,
      });
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Indica" ||
      selectedProductDisplay.productSubType === "FSC Indica Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Indica Hybrid"
    ) {
      return (spacing = {
        x: 75,
        y: 186,
        xOffset: 75,
        yOffset: 186,
        xOffsetNeg: 75,
        yOffsetNeg: 187,
      });
    } else {
      return (spacing = {
        x: 65,
        y: 191,
        xOffset: 65,
        yOffset: 190,
        xOffsetNeg: 65,
        yOffsetNeg: 191,
      });
    }
  };
  const checkTypeColor = () => {
    if (
      selectedProductDisplay.productSubType === "FSC Balanced Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Balanced Hybrid"
    ) {
      return "#008080";
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Sativa" ||
      selectedProductDisplay.productSubType === "FSC Sativa Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Sativa Hybrid"
    ) {
      return "#24855B";
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Indica" ||
      selectedProductDisplay.productSubType === "FSC Indica Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Indica Hybrid"
    ) {
      return "#E75480";
    } else {
      return "#008080";
    }
  };
  const checkTypeFontSize = () => {
    if (
      selectedProductDisplay.productSubType === "FSC Balanced Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Balanced Hybrid"
    ) {
      return { fontSizeMain: 108, fontSizeBackground: 109 };
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Sativa" ||
      selectedProductDisplay.productSubType === "FSC Sativa Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Sativa Hybrid"
    ) {
      return { fontSizeMain: 108, fontSizeBackground: 109 };
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Indica" ||
      selectedProductDisplay.productSubType === "FSC Indica Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Indica Hybrid"
    ) {
      return { fontSizeMain: 108, fontSizeBackground: 109 };
    } else {
      return { fontSizeMain: 80, fontSizeBackground: 81 };
    }
  };

  const generatePDF = async () => {
    // prescriptionPdf();
    setDownloading(true);
    const report = await new JsPDF("p", "mm", [297, 210]);
    report.html(document.querySelector("#label")).then(() => {
      // report.getFontList();
      // report.setFont("Lato-Regular", "normal");
      report.save(
        NameAssemble({ product: selectedProductDisplay }) +
          " " +
          "labelBack.pdf"
      );
    });
    setDownloading(false);
  };

  const generatePDFBack = async () => {
    // prescriptionPdf();
    setDownloading(true);
    const report = await new JsPDF("p", "mm", [297, 210]);
    report.html(document.querySelector("#labelBack")).then(() => {
      report.getFontList();

      report.setFontSize(10);
      report.setTextColor("#FFFFFF");
      report.addFont(
        "ArialMS",
        "ArialMS-Bold",
        "Arial",
        "Arial-bold",
        "Courier-Bold",
        "Times",
        "Courier",
        "courier",
        "Helvetica",
        "Helvetica-Bold",
        "normal",
        "bold"
      );
      // report.addFont(
      //   "Montserrat-Medium-normal.ttf",
      //   "Montserrat-Medium",
      //   "normal"
      // );
      report.addFont(
        "Montserrat-Black-normal.ttf",
        "Montserrat-Black",
        "normal",
        "bold"
      );

      report.text(60, 230, "AVAILABLE TO REGISTERED PRIVATE CLIENTS ONLY");
      // report.addFont("ArialMS", "Arial", "bold");
      report.setFont("Helvetica", "bold");

      report.setFontSize(30);
      report.text(
        selectedProductDisplay.growType === "indoor" ? 120 : 90,
        85,
        selectedProductDisplay.growType.toLocaleUpperCase()
      );
      report.setFontSize(checkTypeFontSize().fontSizeBackground);
      report.setTextColor("#FFFFFF");
      report.text(
        checkType(),
        checkTypeSpacing().xOffset,
        checkTypeSpacing().yOffset,
        {
          angle: 90,
        }
      );
      // botto shadow
      report.text(
        checkType(),
        checkTypeSpacing().xOffsetNeg,
        checkTypeSpacing().yOffsetNeg,
        {
          angle: 90,
        }
      );
      report.setFontSize(checkTypeFontSize().fontSizeMain);
      report.setTextColor(checkTypeColor());
      report.text(checkType(), checkTypeSpacing().x, checkTypeSpacing().y, {
        angle: 90,
      });

      if (nameSpace1 && !nameSpace2 && !nameSpace3) {
        report.setFontSize(nameLength().fontsize);
        report.setTextColor("#FFFFFF");
        report.text(
          nameSpace1,
          // selectedProductDisplay.productName.toLocalUpperCase(),
          (210 / 100) * 29,
          217.5
        );
      }
      if (nameSpace1 && nameSpace2 && !nameSpace3) {
        report.setFontSize(nameLength().fontsize);
        report.setTextColor("#FFFFFF");
        report.text(
          nameSpace1 + " " + nameSpace2,
          // selectedProductDisplay.productName.toLocalUpperCase(),
          (210 / 100) * 29,
          217.5
        );
      }
      if (nameSpace1 && nameSpace2 && nameSpace3) {
        report.setFontSize(nameLength().fontsize);
        report.setTextColor("#FFFFFF");
        report.text(
          nameSpace1 + " " + nameSpace2,
          // selectedProductDisplay.productName.toLocalUpperCase(),
          (210 / 100) * 32,
          208.5
        );
        report.text(
          nameSpace3,
          // selectedProductDisplay.productName.toLocalUpperCase(),
          (210 / 100) * 42,
          220.5
        );
      }
      report.setFontSize(50);
      report.setTextColor("#FFFFFF");
      report.text(
        "R" + " " + Number(selectedProductDisplay.retailPriceInc),
        115,
        67.5
      );

      report.save(
        NameAssemble({ product: selectedProductDisplay }) +
          " " +
          "labelFront.pdf"
      );
    });
    // report.html(document.querySelector("#label")).then(() => {
    //   // report.getFontList();
    //   // report.setFont("Lato-Regular", "normal");
    //   report.save(NameAssemble(selectedProductDisplay) + " " + "labelBack.pdf");
    // });
    setDownloading(false);
  };

  const options = {
    // default is `save`
    method: "open",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "letter",
      // default is 'portrait'
      orientation: "landscape",
    },
    canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: "image/png",
      qualityRatio: 1,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
        compress: true,
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
        useCORS: true,
      },
    },
  };

  const GetImageType = () => {
    if (
      selectedProductDisplay.productSubType === "FSC Balanced Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Balanced Hybrid"
    ) {
      return require("./Template_BALANCED.png");
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Sativa" ||
      selectedProductDisplay.productSubType === "FSC Sativa Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Sativa Hybrid"
    ) {
      return require("./Template_SATIVA.png");
    } else if (
      selectedProductDisplay.productSubType === "FSC Pure Flower Indica" ||
      selectedProductDisplay.productSubType === "FSC Indica Flower Hybrid" ||
      selectedProductDisplay.productSubType === "Indica Hybrid"
    ) {
      return require("./Template_INDICA.png");
    } else {
      return require("./Template_BALANCED.png");
    }
  };

  const DownLoader = () => {
    setDownloading(true);

    setTimeout(() => {
      toPDF(options);
    }, 500);
    setTimeout(() => {
      setDownloading(false);
    }, 1000);
  };

  useEffect(() => {
    TextSplitterResponse();
  }, [selectedProductDisplay]);
  const LabelPdf = ({
    display,
    onCloseClick,
    idKey,
    patientName,
    dob,
    idNumber,
    diagnosis,
    address,
    prescriptionText,
    statusCheck,
    date,
    height,
  }) => {
    return (
      <div
        key={idKey}
        style={{
          width: "100%",
          height: display ? "100%" : 0,

          position: "absolute",
          zIndex: 200,
          transition: "all 0.3s",
          bottom: 0,
        }}
      >
        <div
          style={{
            backgroundColor: BlackAndWhite.secondary,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            // alignItems: "center",
            // paddingLeft: downloading ? 25 : null,
            // fontFamily: "Montserrat",
            letterSpacing: 1.6,
            // justifyContent: "center",
            height: display ? "100%" : 0,
            transition: "all 0.3s",
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {downloading ? null : (
            <div
              style={{ position: "absolute", top: 20, left: 20, zIndex: 2000 }}
            >
              <CloseButton label={"X"} onClick={() => setLabelDisplay(false)} />
            </div>
          )}

          <div
            style={{
              minWidth: 1440,
              maxWidth: 1440,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div
              ref={targetRef}
              style={{
                width: "100%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minHeight: "1697px",
                minHeight: "3914px",
                maxHeight: "3914px",
                position: "relative",
                flexDirection: "column",
                backgroundColor: BlackAndWhite.secondary,
              }}
            >
              {/* page 1 */}
              <div
                style={{
                  minHeight: "2060px",
                  maxHeight: "2060px",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  alt="icon"
                  src={
                    selectedProductDisplay
                      ? GetImageType()
                      : require("./Michael_Template.png")
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    // position: "absolute",
                  }}
                ></img>
                <div
                  style={{
                    position: "absolute",
                    top: 410,
                    right: 315,
                    fontSize: 87,
                    fontWeight: "bold",
                    fontFamily: "monospace",
                  }}
                >
                  {selectedProductDisplay
                    ? "R" + " " + selectedProductDisplay.retailPriceInc
                    : "loading"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 560,
                    right: 310,
                    fontSize: 67,
                    fontWeight: "bold",
                    color: BlackAndWhite.secondary,
                  }}
                >
                  {selectedProductDisplay
                    ? selectedProductDisplay.thcType.toLocaleUpperCase()
                    : "loading"}
                </div>
                <div
                  style={{
                    position: "absolute",
                    fontSize: 110,
                    fontWeight: "bold",
                    color: BlackAndWhite.secondary,
                    width: "100%",
                    textAlign: "center",
                    bottom: 500,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "monospace",
                  }}
                >
                  <div style={{ width: "65%", fontWeight: "bold" }}>
                    {selectedProductDisplay
                      ? selectedProductDisplay.productName.toLocaleUpperCase()
                      : "loading"}
                  </div>
                </div>
              </div>
              <div
                style={{
                  minHeight: "2060px",
                  maxHeight: "2060px",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <img
                  alt="icon"
                  src={require("./Template_BACK.png")}
                  style={{
                    height: "100%",
                    width: "100%",
                    // position: "absolute",
                    marginBottom: 110,
                  }}
                ></img>
              </div>
              {/* <div
                style={{
                  width: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  position: "relative",
                  flexDirection: "column",

                  marginTop: downloading ? 1000 : 1000,
                }}
              >
                {downloading ? (
                  <div style={{ height: 300, padding: 200 }} />
                ) : null}
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // minHeight: downloading ? 3508 : null,
                    // maxHeight: downloading ? 3508 : null,
                    minWidth: 1200,
                    maxWidth: 1200,
                    marginTop: downloading ? 100 : null,
                    // minHeight: 2480,
                  }}
                >
                  <img
                    alt="icon"
                    src={
                      selectedProductDisplay
                        ? GetImageType()
                        : require("./Michael_Template.png")
                    }
                    style={{
                      height: "100%",
                      width: "100%",
                      // position: "absolute",
                    }}
                  ></img>
                  <div
                    style={{
                      position: "absolute",
                      top: 320,
                      right: 260,
                      fontSize: 87,
                      fontWeight: "bold",
                    }}
                  >
                    {selectedProductDisplay
                      ? "R" + " " + selectedProductDisplay.retailPriceInc
                      : "loading"}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      fontSize: 80,
                      fontWeight: "bold",
                      color: BlackAndWhite.secondary,
                      width: "60%",
                      textAlign: "center",
                      bottom: 400,
                    }}
                  >
                    {selectedProductDisplay
                      ? selectedProductDisplay.productName.toLocaleUpperCase()
                      : "loading"}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {downloading ? null : (
          <div style={{ position: "absolute", top: 20, right: 20 }}>
            <BaseButtons
              label={"DOWNLOAD LABEL"}
              onClick={() => {
                DownLoader();
              }}
              mini={true}
              marginTopFalse={true}
              height={40}
              borderRadius={8}
            />
          </div>
        )}
      </div>
    );
  };
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        width: "100%",
        position: "absolute",
        zIndex: 1000000,
        overflow: "hidden",
        bottom: 0,
        transition: "height 0.3s",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
      <div style={{ position: "absolute", top: 20, right: 20 }}>
        <BaseButtons
          label={"LABEL DOWNLOAD"}
          // onClick={() => setLabelDisplay(true)}
          onClick={() => {
            DownLoader();
          }}
          mini={true}
          marginTopFalse={true}
          marginTop={0}
          size={170}
          height={40}
          borderRadius={8}
        />
      </div>
      <div style={{ width: "100%", marginTop: 25, marginBottom: 20 }}>
        <div style={{ marginLeft: 100, fontSize: 18, fontWeight: "bold" }}>
          PRODUCT DISPLAY{" "}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: 1000,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flex: 0.5,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <img
              alt="logo"
              src={
                selectedProductDisplay ? selectedProductDisplay.imageUrl : null
              }
              style={{
                height: "100%",
                marginLeft: 200,
                maxHeight: 300,
                marginBottom: 20,
                minHeight: 300,
              }}
            />
            <div
              style={{
                position: "absolute",

                height: 60,
                // paddingLeft: 20,
                paddingRight: 20,
                left: 20,
                borderRadius: 8,
                // backgroundColor: BlackAndWhite.primary,
                // color: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: 20,
                flexDirection: "column",
                marginBottom: 100,
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  opacity: 0.8,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                THC TYPE
              </div>
              {selectedProductDisplay
                ? selectedProductDisplay.thcType
                : "loading"}
            </div>
            <div
              style={{
                position: "absolute",

                height: 60,
                // paddingLeft: 20,
                paddingRight: 20,
                left: 20,
                borderRadius: 8,
                // backgroundColor: BlackAndWhite.primary,
                // color: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: 50,
                flexDirection: "column",
                marginBottom: 235,
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "normal",
                  opacity: 0.8,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                PRICE
              </div>
              R{" "}
              {selectedProductDisplay
                ? selectedProductDisplay.retailPriceInc
                : "loading"}
            </div>
            <div
              style={{
                position: "absolute",
                // top: 50,
                height: 60,
                // paddingLeft: 20,
                paddingRight: 20,
                left: 20,
                borderRadius: 8,
                // backgroundColor: BlackAndWhite.primary,
                // color: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: 20,
                flexDirection: "column",
                marginTop: 10,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  opacity: 0.8,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                THC CONTENT
              </div>
              {selectedProductDisplay
                ? selectedProductDisplay.thcContent
                : "loading"}
            </div>
            <div
              style={{
                position: "absolute",
                // top: 50,
                height: 60,
                // paddingLeft: 20,
                paddingRight: 20,
                left: 20,
                borderRadius: 8,
                // backgroundColor: BlackAndWhite.primary,
                // color: BlackAndWhite.secondary,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontWeight: "bold",
                fontSize: 20,
                flexDirection: "column",
                marginTop: 120,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "normal",
                  opacity: 0.8,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                MEASUREMENT
              </div>
              {selectedProductDisplay
                ? selectedProductDisplay.size +
                  " " +
                  selectedProductDisplay.quantityDenomiation
                : "loading"}
            </div>
          </div>
          <div
            style={{
              flex: 0.5,

              width: "100%",
              position: "relative",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <div style={{ marginLeft: 20, marginTop: 10, width: "87%" }}>
              <div style={{ fontSize: 14, opacity: 0.7 }}>PRODUCT NAME</div>
              <div
                style={{
                  fontSize: 20,
                  marginTop: 10,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {selectedProductDisplay
                  ? NameAssemble({ product: selectedProductDisplay })
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                width: "90%",
                height: 50,
                backgroundColor: BlackAndWhite.primary,
                marginTop: 20,
                borderRadius: 8,
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                overflow: "hidden",
                // marginLeft: 20,
                // position: "absolute",
                // top: -60,
              }}
            >
              <div
                style={{
                  // marginTop: 50,
                  fontSize: 18,
                  width: "95%",
                  fontWeight: "bold",

                  color: BlackAndWhite.secondary,
                }}
              >
                INGREDIENTS
              </div>
            </div>

            <div
              style={{
                marginTop: 40,
                fontSize: 20,
                width: "80%",
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              {selectedProductDisplay
                ? selectedProductDisplay.ingredients[0].value
                : "loading"}
            </div>
          </div>
        </div>
        <div
          style={{
            flex: 0.5,
            display: "flex",

            flexDirection: "column",
          }}
        >
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            <div style={{ fontSize: 14, opacity: 0.7 }}>TYPE</div>
            <div style={{ fontSize: 20, marginTop: 10 }}>
              {selectedProductDisplay
                ? selectedProductDisplay.productType
                : "loading"}
            </div>
          </div>
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            <div style={{ fontSize: 14, opacity: 0.7 }}>SUB TYPE</div>
            <div style={{ fontSize: 20, marginTop: 10 }}>
              {selectedProductDisplay
                ? selectedProductDisplay.productSubType
                : "loading"}
            </div>
          </div>
          <div style={{ marginLeft: 20, marginTop: 10 }}>
            <div style={{ fontSize: 14, opacity: 0.7 }}>SKU</div>
            <div style={{ fontSize: 20, marginTop: 10 }}>
              {selectedProductDisplay ? selectedProductDisplay.sku : "loading"}
            </div>
          </div>
          {/* <div
            style={{
              width: "90%",
              height: 50,
              backgroundColor: BlackAndWhite.primary,
              marginTop: 20,
              borderRadius: 8,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              overflow: "hidden",
              // marginLeft: 20,
            }}
          >
            <BaseButtons
              mini={true}
              marginTopFalse={true}
              label={"QUICK DESCRIPTION"}
              invert={selectedDescription === "quick" ? false : true}
              height={35}
              size={170}
              borderRadius={8}
              onClick={() => setSelectedDescription("quick")}
            />
            <BaseButtons
              mini={true}
              marginTopFalse={true}
              label={"FULL DESCRIPTION"}
              invert={selectedDescription === "full" ? false : true}
              height={35}
              size={160}
              borderRadius={8}
              onClick={() => setSelectedDescription("full")}
            />
            <BaseButtons
              mini={true}
              marginTopFalse={true}
              label={"HOW TO DESCRIPTION"}
              invert={selectedDescription === "how" ? false : true}
              height={35}
              size={180}
              borderRadius={8}
              onClick={() => setSelectedDescription("how")}
            />
         
          </div> */}
          <div
            style={{
              width: "90%",
              height: 50,
              backgroundColor: BlackAndWhite.primary,
              marginTop: 20,
              borderRadius: 8,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              overflow: "hidden",
              // marginLeft: 20,
              // position: "absolute",
              // top: -60,
            }}
          >
            <div
              style={{
                // marginTop: 50,
                fontSize: 18,
                width: "95%",
                fontWeight: "bold",

                color: BlackAndWhite.secondary,
              }}
            >
              QUICK DESCRIPTION
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: 30,
                fontSize: 20,
                width: "80%",
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              <div>
                {selectedProductDisplay
                  ? selectedProductDisplay.menuDescription
                  : "loading"}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "90%",
              height: 50,
              backgroundColor: BlackAndWhite.primary,
              marginTop: 20,
              borderRadius: 8,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              overflow: "hidden",
              // marginLeft: 20,
              // position: "absolute",
              // top: -60,
            }}
          >
            <div
              style={{
                // marginTop: 50,
                fontSize: 18,
                width: "95%",
                fontWeight: "bold",

                color: BlackAndWhite.secondary,
              }}
            >
              FULL DESCRIPTION
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: 30,
                fontSize: 20,
                width: "80%",
                marginLeft: 20,
                marginBottom: 20,
              }}
            >
              <div>
                {selectedProductDisplay
                  ? selectedProductDisplay.description
                  : "loading"}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "90%",
              height: 50,
              backgroundColor: BlackAndWhite.primary,
              marginTop: 20,
              borderRadius: 8,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              overflow: "hidden",
              // marginLeft: 20,
              // position: "absolute",
              // top: -60,
            }}
          >
            <div
              style={{
                // marginTop: 50,
                fontSize: 18,
                width: "95%",
                fontWeight: "bold",

                color: BlackAndWhite.secondary,
              }}
            >
              HOW TO USE
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: 30,
                fontSize: 20,
                width: "80%",
                marginLeft: 20,
                marginBottom: 60,
              }}
            >
              <div>
                {selectedProductDisplay
                  ? selectedProductDisplay.useDescription
                  : "loading"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LabelPdf
        display={labelDisplay}
        onCloseClick={() => setLabelDisplay(false)}
      />
    </div>
  );
};

export default ProductKnowledgeDisplayModal;
